import { GetMatrix } from 'graphql/queries';
import React from 'react';
import SelectGrouped from 'App/components/Forms/SelectGrouped';
import { useLazyQuery } from '@apollo/client';

export const FindingMatrixForm = ({
  register,
  errors,
  values,
  setValue,
  getValues,
  matrixes,
  likelihoods,
  unregister,
  riskRegisters,
  impacts,
}) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const [getExisting, { data }] = useLazyQuery(GetMatrix);

  const newArray = matrixes.slice();
  newArray.unshift({ title: 'Choose your own', id: -1 });
  React.useEffect(() => {
    register('riskMatrixId', { required: true });
    setValue('riskMatrixId', values?.riskMatrix?.id);
  }, [register, values, setValue]);

  React.useEffect(() => {
    if (isOpened !== -1) {
      register('riskMatrixId', { required: true });
      setValue('riskMatrixId', isOpened);
      unregister('impactRatingId');
      unregister('likelihoodRatingId');
      unregister('riskRegisterId');
    } else {
      unregister('riskMatrixId');
      register('impactRatingId', { required: true });
      register('likelihoodRatingId', { required: true });
      register('riskRegisterId', { required: true });
    }
  }, [isOpened, register, unregister, setValue]);
  const checkMatrix = () => {
    if (
      getValues('riskRegisterId') &&
      getValues('likelihoodRatingId') &&
      getValues('impactRatingId')
    ) {
      getExisting({
        variables: {
          riskRegisterId: getValues('riskRegisterId'),
          likelihoodRatingId: getValues('likelihoodRatingId'),
          impactRatingId: getValues('impactRatingId'),
        },
      });
    }
  };

  const getLabel = item =>
    item?.likelihoodRating
      ? item.title +
        ': ' +
        item?.riskRegister?.title +
        ' - ' +
        item?.likelihoodRating?.title +
        '/' +
        item?.impactRating?.title
      : item.title;

  return (
    <>
      <SelectGrouped
        name="riskMatrixId"
        label="Select a risk matrix"
        smallText="RiskMatrix value: Risk Register - Likelihood Rating / Impact Rating"
        defaultValue={values?.riskMatrix?.id}
        error={errors?.riskMatrixId}
        groupedOptions={newArray?.map(item => {
          return {
            label: getLabel(item),
            value: item.id,
          };
        })}
        onChange={answer => {
          setValue('riskMatrixId', answer.value);
          setIsOpened(answer.value);
        }}
      />

      {isOpened === -1 && (
        <>
          {data && data?.riskMatrix && getValues('impactRatingId') && (
            <span className="label label-warning" data-cy="warning">
              Warning: you're creating a new matrix row dont't forget to edit it
              in the config
            </span>
          )}
          <SelectGrouped
            name="riskRegisterId"
            label="Risk Register"
            required
            defaultValue={values?.riskRegisterId?.id}
            error={errors?.riskRegisterId}
            groupedOptions={riskRegisters.map(item => {
              return { label: item.title, value: item.id };
            })}
            onChange={answer => {
              setValue('riskRegisterId', answer.value);
              checkMatrix();
            }}
          />
          <SelectGrouped
            name="likelihoodRatingId"
            label="Likelihood"
            required
            defaultValue={values?.likelihoodRating?.id}
            error={errors?.likelihoodRatingId}
            groupedOptions={likelihoods.map(item => {
              return { label: item.title, value: item.id };
            })}
            onChange={answer => {
              setValue('likelihoodRatingId', answer.value);
              checkMatrix();
            }}
          />
          <SelectGrouped
            name="impactRatingId"
            label="Impact"
            required
            defaultValue={values?.impactRating?.id}
            error={errors?.impactRatingId}
            groupedOptions={impacts.map(item => {
              return { label: item.title, value: item.id };
            })}
            onChange={answer => {
              setValue('impactRatingId', answer.value);
              checkMatrix();
            }}
          />
        </>
      )}
    </>
  );
};

export default FindingMatrixForm;
