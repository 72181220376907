import { GetCustomFields, GetImpact, GetLikelihood } from 'graphql/queries';

import { CustomFields } from './Custumfields';
import FormInput from 'App/components/Forms/Input';
import { Loader } from 'App/components/Loader';
import React from 'react';
import { useQuery } from '@apollo/client';

export const LikelihoodImpactForm = ({
  register,
  errors,
  control,
  values,
  setValue,
}) => {
  const data = JSON.parse(values || '{}');
  let query = GetImpact;
  let customType = 'IMPACT';
  if (data?.type === 'likelihoodRating') {
    query = GetLikelihood;
    customType = 'LIKELIHOOD';
  }

  const { data: dataForEdit, error } = useQuery(query, {
    variables: { id: data?.id },
  });

  const { data: customFields, loading } = useQuery(GetCustomFields, {
    variables: { customizable: customType },
  });

  register('id');

  React.useEffect(() => {
    setValue('id', data.id);
  }, [setValue, values, data, dataForEdit, customFields]);

  if (loading) return <Loader loading={true} />;

  return dataForEdit ? (
    <>
      <FormInput
        name="title"
        defaultValue={dataForEdit[data.type].title}
        inputRef={register({ required: true })}
        required
        error={error?.title}
      />

      <CustomFields
        register={register}
        errors={errors}
        control={control}
        values={
          dataForEdit && dataForEdit[data.type]
            ? dataForEdit[data.type]?.attribs
            : {}
        }
        fields={customFields}
        setValue={setValue}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};
