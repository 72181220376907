import PropTypes from 'prop-types';
import React from 'react';
import CheckboxTree from 'react-checkbox-tree';

const NodeTree = ({
  name,
  onCheck,
  id,
  label,
  onlyLeafCheckboxes,
  nodes,
  checked,
  expandOnClick,
  ...props
}) => {
  const [expanded, setExpanded] = React.useState([]);
  const [search, setSearch] = React.useState('');

  const filteredNode = nodes?.filter(item => {
    return item.label.toLowerCase().includes(search.toLowerCase());
  });
  return (
    <>
      <input
        type="text"
        className="form-control  mx-sm-3 mb-2"
        placeholder="Type in here to filter..."
        onChange={e => {
          setSearch(e.target.value);
        }}
      />
      <div
        style={{
          height: '20rem',
          overflowY: 'auto',
        }}
      >
        <div className="form-group">
          <label className="form-label">{label} *</label>
          <CheckboxTree
            nodes={filteredNode}
            checked={checked}
            className={`${name}-cy-checkbox`}
            onlyLeafCheckboxes={onlyLeafCheckboxes}
            name={name}
            expandOnClick={expandOnClick}
            expanded={expanded}
            onCheck={onCheck}
            onExpand={expand => setExpanded([...expand])}
          />
        </div>
      </div>
    </>
  );
};

NodeTree.defaultProps = {
  onlyLeafCheckboxes: false,
  expandOnClick: false,
  nodes: [],
  checked: [],
};
NodeTree.propTypes = {
  name: PropTypes.string.isRequired,
  expandOnClick: PropTypes.bool,
  onlyLeafCheckboxes: PropTypes.bool,
  nodes: PropTypes.array.isRequired,
  checked: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default NodeTree;
