import FormInput from 'App/components/Forms/Input';
import { Loader } from 'App/components/Loader';
import React from 'react';
import SelectGrouped from 'App/components/Forms/SelectGrouped';

export const WorkflowForm = ({
  register,
  errors,
  control,
  values,
  setValue,
  ...props
}) => {
  const [nextExecutionTime, setNextExecutionTime] = React.useState(
    values?.nextExecutionTime ? Date.parse(values?.nextExecutionTime) : null,
  );
  const [lastPickedupTime, setLastPickedupTime] = React.useState(
    values?.lastPickedupTime ? Date.parse(values?.lastPickedupTime) : null,
  );
  const [inputList, setInputList] = React.useState([{ label: '', value: '' }]);
  React.useEffect(() => {
    register('special');
    register('data');
    register('nextExecutionTime');
    register('lastPickedupTime');
    if (values?.id) {
      register('id');
      setValue('id', values?.id);
    }
    if (values?.data) {
      const tab = JSON.parse(values?.data);
      tab.push({ label: '', value: '' });
      setInputList(tab);
    }
  }, [register, setValue, values, setInputList]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setValue('data', JSON.stringify(list));
  };

  const handleRemoveClick = (event, index) => {
    event.preventDefault();
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setValue('data', JSON.stringify(list));
  };

  const handleAddClick = () => {
    setInputList([...inputList, { label: '', value: '' }]);
    setValue('data', JSON.stringify(inputList));
  };
  return values ? (
    <>
      <FormInput
        name="name"
        defaultValue={values?.name}
        inputRef={register({ required: false })}
        required
        error={errors?.name}
      />
      <SelectGrouped
        name="special"
        required
        label="Special"
        defaultValue={values?.special || 'false'}
        error={errors?.special}
        groupedOptions={[
          { label: 'Yes', value: true },
          { label: 'No', value: 'false' },
        ]}
        // eslint-disable-next-line no-eval
        onChange={answer => setValue('special', eval(answer.value))}
      />
      <FormInput
        name="schedule"
        defaultValue={values?.schedule}
        inputRef={register({ required: false })}
        error={errors?.schedule}
      />
      <FormInput
        name="nextUpdateTime"
        inputRef={register({ required: false })}
        type="datetime"
        defaultValue={nextExecutionTime}
        error={errors?.nextExecutionTime}
        onChange={date => {
          setNextExecutionTime(date);
          setValue('nextExecutionTime', date);
        }}
      />

      <FormInput
        name="lastPickedupTime"
        inputRef={register({ required: false })}
        type="datetime"
        error={errors?.lastPickedupTime}
        defaultValue={lastPickedupTime}
        onChange={date => {
          setLastPickedupTime(date);
          setValue('lastPickedupTime', date);
        }}
      />
      <table>
        <tbody>
          {inputList.map((x, i) => {
            return (
              <tr className="box" key={i}>
                <td>
                  <input
                    name="label"
                    placeholder="Enter label"
                    className="form-control"
                    value={x.label}
                    onChange={e => handleInputChange(e, i)}
                  />
                </td>
                <td>
                  <input
                    name="value"
                    placeholder="Enter value"
                    className="form-control"
                    value={x.value}
                    onChange={e => handleInputChange(e, i)}
                  />
                </td>
                <td className="btn-box">
                  {inputList.length !== 1 && (
                    <button
                      className="mr10"
                      onClick={e => handleRemoveClick(e, i)}
                    >
                      Remove
                    </button>
                  )}
                  {inputList.length - 1 === i && (
                    <button onClick={handleAddClick}>Add</button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  ) : (
    <Loader loading={true} />
  );
};
