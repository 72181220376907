import { gql } from '@apollo/client';

export const GetPolicies = gql`
  query policies {
    policies {
      id
      title
      description
      attribs
      nextReview
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetPolicy = gql`
  query policy($id: ID!) {
    policy(id: $id) {
      id
      title
      description
      attribs
      nextReview
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetPoliciesControls = gql`
  query policies {
    policies {
      id
      title
      controls {
        id
        title
      }
    }
  }
`;
export const GetPoliciesDetails = gql`
  query policies {
    policies {
      id
      title
      nextReview
      controls {
        id
        title
        controlTests {
          id
          title
        }
      }
    }
  }
`;
