import * as Cookies from 'js-cookie';

import React, { useEffect } from 'react';

import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const ListPolicies = React.lazy(() => import('./App/domains/policies'));
const ListSuppliers = React.lazy(() => import('./App/domains/suppliers'));
const ListStandards = React.lazy(() => import('./App/domains/standards'));
const ListGapAnalysis = React.lazy(() =>
  import('./App/domains/gap-analysis/index'),
);
const GapAnalysis = React.lazy(() =>
  import('./App/domains/gap-analysis/analysis'),
);
const ListRiskRegisters = React.lazy(() =>
  import('./App/domains/risk-registers'),
);
const Matrix = React.lazy(() => import('./App/domains/matrix'));
const ListAudits = React.lazy(() => import('./App/domains/audits'));
const ListMaturityScores = React.lazy(() =>
  import('./App/domains/maturity_scores'),
);
const ListFindings = React.lazy(() => import('./App/domains/findings'));
const ListRisks = React.lazy(() => import('./App/domains/risks'));
const ListDocuments = React.lazy(() => import('./App/domains/documents'));
const ListRepositories = React.lazy(() => import('./App/domains/repositories'));
const ListIncidents = React.lazy(() => import('./App/domains/incidents'));
const DetailIncident = React.lazy(() =>
  import('./App/domains/incidents/detail'),
);
const ListCustomFields = React.lazy(() =>
  import('./App/domains/customs-fields'),
);
const ListRegisterMatrix = React.lazy(() =>
  import('./App/domains/matrix/list'),
);
const ListUsers = React.lazy(() => import('./App/domains/users'));
const ListSurveys = React.lazy(() => import('./App/domains/surveys'));
const SurveyCreator = React.lazy(() => import('./App/domains/surveys/creator'));
const ListControls = React.lazy(() => import('./App/domains/controls'));
const ListControlTests = React.lazy(() =>
  import('./App/domains/control_tests'),
);
const ListTestResults = React.lazy(() =>
  import('./App/domains/control_test_results'),
);

const ListPackages = React.lazy(() => import('./App/domains/packages'));
const Dashboard = React.lazy(() => import('./App/domains/dashboard'));
const Pivot = React.lazy(() => import('./App/domains/PivotTableContainer'));
const DetailSupplier = React.lazy(() =>
  import('./App/domains/suppliers/details'),
);
const CampaignDetail = React.lazy(() => import('./App/domains/campaign'));
export const LogoutHandler = () => {
  useEffect(() => {
    Cookies.remove('token');
    Cookies.remove('user');
    localStorage.clear();
    window.location = '/auth/signin';
  }, []);

  return <div>Logging out!</div>;
};

const routes = [
  {
    path: '/suppliers',
    exact: true,
    name: 'Suppliers',
    component: ListSuppliers,
    flags: ['trial:suppliers'],
    // flags: ['trial:suppliers', 'testing:suppliers'],
  },
  {
    path: '/data-explorer',
    exact: true,
    name: 'Data explorer',
    component: Pivot,
  },
  {
    path: '/suppliers/:id',
    exact: true,
    name: 'Detail Supplier',
    component: DetailSupplier,
    flags: ['trial:detail_supplier' ],
  },
  {
    path: '/suppliers/:supplierId/campaigns/:id',
    exact: true,
    name: 'Campaign Detail',
    component: CampaignDetail,
    flags: ['trial:campaign_detail' ],
  },
  {
    path: '/surveys',
    exact: true,
    name: 'Surveys',
    component: ListSurveys,
    flags: ['trial:surveys' ],
  },
  {
    path: '/surveys/create',
    exact: true,
    name: 'Survey Creator',
    flags: ['trial:survey_creator' ],
    component: SurveyCreator,
  },
  {
    path: '/surveys/:id/update',
    exact: true,
    name: 'Survey Update',
    component: SurveyCreator,
    flags: ['trial:survey_creator'],
  },
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/policies',
    exact: true,
    flags: ['trial:policies'],
    name: 'Policies',
    component: ListPolicies,
  },
  {
    path: '/maturity-scores',
    exact: true,
    flags: ['trial:maturity_scores'],
    name: 'Maturity Score',
    component: ListMaturityScores,
  },
  {
    path: '/standards',
    exact: true,
    name: 'Standards',
    flags: ['trial:standards'],
    component: ListStandards,
  },
  {
    path: '/gap-analysis',
    exact: true,
    name: 'List Gap analysis',
    flags: ['trial:gap_analysis'],
    component: ListGapAnalysis,
  },

  {
    path: '/gap-analysis/:id',
    exact: true,
    name: 'Gap analysis',
    flags: ['trial:gap_analysis'],
    component: GapAnalysis,
  },
  {
    path: '/policies/:id/controls',
    exact: true,
    flags: ['trial:controls'],
    name: 'Controls',
    component: ListControls,
  },
  {
    path: '/policies/:policyId/controls/:controlId/tests',
    exact: true,
    flags: ['trial:control_tests'],
    name: 'Control Tests',
    component: ListControlTests,
  },
  {
    path: '/policies/:policyId/controls/:controlId/tests/:testId/results',
    exact: true,
    name: 'Test Results',
    flags: ['trial:test_results'],
    component: ListTestResults,
  },
  {
    path: '/custom-fields',
    exact: true,
    flags: ['trial:custom_fields'],
    name: 'Custom fields',
    component: ListCustomFields,
  },
  {
    path: '/risk-registers/:id',
    exact: true,
    name: 'Risks',
    flags: ['trial:risks'],
    component: ListRisks,
  },
  {
    path: '/risk-registers',
    exact: true,
    flags: ['trial:risk_registers'],
    name: 'Risk Registers',
    component: ListRiskRegisters,
  },
  {
    path: '/incidents/:id',
    exact: true,
    flags: ['trial:detail_incident'],
    name: 'Detail incident',
    component: DetailIncident,
  },
  {
    path: '/incidents',
    exact: true,
    name: 'Test Results',
    flags: ['trial:incidents'],
    component: ListIncidents,
  },
  {
    path: '/repositories/:id/documents',
    exact: true,
    name: 'Documents',
    flags: ['trial:documents'],
    component: ListDocuments,
  },
  {
    path: '/repositories',
    flags: ['trial:repositories'],
    exact: true,
    name: 'Repositories',
    component: ListRepositories,
  },
  {
    path: '/config/risk-register',
    flags: ['trial:config_risk_register'],
    exact: true,
    name: 'Config risk register',
    component: ListRegisterMatrix,
  },
  {
    path: '/audits/:id',
    exact: true,
    flags: ['trial:audit_detail'],
    name: 'Audit detail',
    component: ListFindings,
  },
  {
    path: '/audits',
    exact: true,
    flags: ['trial:audits'],
    name: 'Audits',
    component: ListAudits,
  },
  {
    path: '/packages',
    exact: true,
    flags: ['trial:packages'],
    name: 'Packages',
    component: ListPackages,
  },
  {
    path: '/users',
    exact: true,
    name: 'Users',
    flags: ['trial:users'],
    component: ListUsers,
  },
  {
    path: '/config/risk-register/:id/matrix',
    exact: true,
    flags: ['trial:matrix'],
    name: 'Matrix',
    component: Matrix,
  },

  { path: '/logout/', exact: true, name: 'Logout', component: LogoutHandler },
];

export default routes;
