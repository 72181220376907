import { defineMessages } from 'react-intl';

export const scope = 'grc.containers';

export default defineMessages({
  Add: {
    id: `${scope}.add`,
    defaultMessage: 'Add ',
  },
  Edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit ',
  },
  List: {
    id: `${scope}.list`,
    defaultMessage: 'List of ',
  },
  Detail: {
    id: `${scope}.detail`,
    defaultMessage: 'Detail',
  },
  Remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Remove ',
  },
  Delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete ',
  },
  Title: {
    id: `${scope}.title`,
    defaultMessage: 'Title',
  },
  Name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  Description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  Status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  Responsible: {
    id: `${scope}.responsible`,
    defaultMessage: 'Responsible',
  },
  Executioner: {
    id: `${scope}.executioner`,
    defaultMessage: 'Executioner',
  },
  Approver: {
    id: `${scope}.approver`,
    defaultMessage: 'Approver',
  },
  Reviewer: {
    id: `${scope}.reviewer`,
    defaultMessage: 'Reviewer',
  },
  Owner: {
    id: `${scope}.owner`,
    defaultMessage: 'Owner',
  },
  Audits: {
    id: `${scope}.audits`,
    defaultMessage: 'Audits',
  },
  Dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: 'Dashboard',
  },
  Audit: {
    id: `${scope}.audit`,
    defaultMessage: 'Audit',
  },
  Finding: {
    id: `${scope}.finding`,
    defaultMessage: 'Finding',
  },
  Findings: {
    id: `${scope}.findings`,
    defaultMessage: 'Findings ',
  },
  Involved: {
    id: `${scope}.Involved`,
    defaultMessage: 'Involved personnel ',
  },
  Policies: {
    id: `${scope}.policies`,
    defaultMessage: 'Policies ',
  },
  Policy: {
    id: `${scope}.policy`,
    defaultMessage: 'Policy ',
  },
  ListPolicies: {
    id: `${scope}.listPolicies`,
    defaultMessage: 'List of Policies',
  },
  ListFindings: {
    id: `${scope}.listFindings`,
    defaultMessage: 'List of findings',
  },
  ListAudits: {
    id: `${scope}.listAudits`,
    defaultMessage: 'List of audits',
  },
  AssignTo: {
    id: `${scope}.assignTo`,
    defaultMessage: 'Assign to ',
  },
  LinkTo: {
    id: `${scope}.linkTo`,
    defaultMessage: 'Link to ',
  },
  Period: {
    id: `${scope}.periode`,
    defaultMessage: 'Period',
  },
  Certification: {
    id: `${scope}.certification`,
    defaultMessage: 'Certification',
  },
  ExecutionParty: {
    id: `${scope}.executionParty`,
    defaultMessage: 'Execution party',
  },
  Internal: {
    id: `${scope}.internal`,
    defaultMessage: 'Internal',
  },
  Controls: {
    id: `${scope}.controls`,
    defaultMessage: 'Controls ',
  },
  Control: {
    id: `${scope}.control`,
    defaultMessage: 'Control ',
  },
  ControlTests: {
    id: `${scope}.controlTests`,
    defaultMessage: 'Control Tests',
  },
  Risk: {
    id: `${scope}.risk`,
    defaultMessage: 'Risk ',
  },
  Risks: {
    id: `${scope}.risks`,
    defaultMessage: 'Risks ',
  },
  ControlTest: {
    id: `${scope}.controlTest`,
    defaultMessage: 'Control Test',
  },
  Results: {
    id: `${scope}.results`,
    defaultMessage: 'Test results',
  },
  Result: {
    id: `${scope}.result`,
    defaultMessage: 'Test result ',
  },
  GapAnalysis: {
    id: `${scope}.gapAnalysis`,
    defaultMessage: 'Gap Analysis ',
  },
  Standard: {
    id: `${scope}.standard`,
    defaultMessage: 'Standard ',
  },
  Standards: {
    id: `${scope}.standards`,
    defaultMessage: 'Standards ',
  },
  StandardClauses: {
    id: `${scope}.standardClauses`,
    defaultMessage: 'Standard Clauses ',
  },
  StandardClause: {
    id: `${scope}.standardClause`,
    defaultMessage: 'Standard Clause ',
  },
  CustomFields: {
    id: `${scope}.customFields`,
    defaultMessage: 'Custom Fields',
  },
  MaturityLevel: {
    id: `${scope}.maturityLevel`,
    defaultMessage: 'Maturity Level  ',
  },
  StandardToInclude: {
    id: `${scope}.standardToInclude`,
    defaultMessage: 'Standards to include',
  },
  ControlsToInclude: {
    id: `${scope}.controlsToInclude`,
    defaultMessage: 'Controls to include',
  },
  For: {
    id: `${scope}.for`,
    defaultMessage: 'for',
  },
  Of: {
    id: `${scope}.of`,
    defaultMessage: 'of ',
  },
  Incidents: {
    id: `${scope}.incidents`,
    defaultMessage: 'Incidents',
  },
  Incident: {
    id: `${scope}.incident`,
    defaultMessage: 'Incident',
  },
  managements: {
    id: `${scope}.managements`,
    defaultMessage: 'managements',
  },
  field: {
    id: `${scope}.field`,
    defaultMessage: 'field',
  },
  RiskRegister: {
    id: `${scope}.riskRegister`,
    defaultMessage: 'Risk Register',
  },
  RiskRating: {
    id: `${scope}.riskRating`,
    defaultMessage: 'Risk Rating',
  },
  Impact: {
    id: `${scope}.impact`,
    defaultMessage: 'Impact',
  },
  Likelihood: {
    id: `${scope}.likelihood`,
    defaultMessage: 'Likelihood',
  },
  Action: {
    id: `${scope}.action`,
    defaultMessage: 'Action',
  },
  NextReview: {
    id: `${scope}.nextReview`,
    defaultMessage: 'NextReview',
  },
  MaturityScores: {
    id: `${scope}.maturityScores`,
    defaultMessage: 'Maturity Scores',
  },
  MaturityScore: {
    id: `${scope}.maturityScore`,
    defaultMessage: 'Maturity Score',
  },
  Field: {
    id: `${scope}.field`,
    defaultMessage: 'Field',
  },
  MandatoryQuestion: {
    id: `${scope}.mandatoryQuestion`,
    defaultMessage: 'Is it a mandatory field ?',
  },
  DataType: {
    id: `${scope}.dataType`,
    defaultMessage: 'Data type',
  },

  RiskManagement: {
    id: `${scope}.Risk Management`,
    defaultMessage: 'Risk Management',
  },
  NextUpdateTime: {
    id: `${scope}.NextUpdateTime`,
    defaultMessage: 'Next Update Time',
  },
  Comments: {
    id: `${scope}.Comments`,
    defaultMessage: 'Comments',
  },
  Comment: {
    id: `${scope}.Comment`,
    defaultMessage: 'Comment',
  },
  By: {
    id: `${scope}.by`,
    defaultMessage: 'by',
  },
  Packages: {
    id: `${scope}.packages`,
    defaultMessage: 'Packages',
  },
  Email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  Users: {
    id: `${scope}.users`,
    defaultMessage: 'Users',
  },
  User: {
    id: `${scope}.user`,
    defaultMessage: 'User',
  },
  Company: {
    id: `${scope}.company`,
    defaultMessage: 'Company',
  },
  StartDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Start Date',
  },
  EndDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'End Date',
  },
  Documents: {
    id: `${scope}.documents`,
    defaultMessage: 'Documents ',
  },
  Document: {
    id: `${scope}.document`,
    defaultMessage: 'Document ',
  },
  Repository: {
    id: `${scope}.repository`,
    defaultMessage: 'Repository ',
  },
  Repositories: {
    id: `${scope}.repositories`,
    defaultMessage: 'Repositories ',
  },
  FileName: {
    id: `${scope}.fileName`,
    defaultMessage: 'File Name ',
  },
  Download: {
    id: `${scope}.download`,
    defaultMessage: 'Download ',
  },
  GoBack: {
    id: `${scope}.goback`,
    defaultMessage: 'Go back to previous page ',
  },
  Workflow: {
    id: `${scope}.workflow`,
    defaultMessage: 'Workflow',
  },
  Properties: {
    id: `${scope}.properties`,
    defaultMessage: 'Properties',
  },
  Suppliers: {
    id: `$scope.suppliers`,
    defaultMessage: 'Suppliers ',
  },
  Supplier: {
    id: `$scope.supplier`,
    defaultMessage: 'Supplier ',
  },
  Address: {
    id: `$scope.address`,
    defaultMessage: 'Address ',
  },
  Phone: {
    id: `$scope.phone`,
    defaultMessage: 'Phone number ',
  },
  Surveys: {
    id: `$scope.surveys`,
    defaultMessage: 'Surveys ',
  },
  Survey: {
    id: `$scope.survey`,
    defaultMessage: 'Survey ',
  },
  Campaigns: {
    id: `$scope.campaigns`,
    defaultMessage: 'Campaigns ',
  },
  Campaign: {
    id: `$scope.campaign`,
    defaultMessage: 'Campaign ',
  },
  Respondents: {
    id: `$scope.respondents`,
    defaultMessage: 'Respondents ',
  },
  Respondent: {
    id: `$scope.respondent`,
    defaultMessage: 'Respondent ',
  },
  Form: {
    id: `${scope}.form`,
    defaultMessage: 'form',
  },
  To: {
    id: `${scope}.to`,
    defaultMessage: ' to ',
  },
});
