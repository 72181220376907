import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { Col, Row } from 'react-bootstrap';
import { Controller, useFieldArray } from 'react-hook-form';

import FormInput from 'App/components/Forms/Input';
import { Loader } from 'App/components/Loader';
import React from 'react';

export const SendSurveyForm = ({
  register,
  errors,
  values,
  control,
  setValue,
  campaignId,
  surveys,
  ...props
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'respondents',
    keyName: '_id',
  });

  React.useEffect(() => {
    register('campaignId', { required: true });
    if (campaignId) setValue('campaignId', campaignId);
    if (fields.length === 0) {
      append({});
    }
  }, [values, errors, register, setValue, append, fields, campaignId]);

  return values ? (
    <>
      {fields.map((item, index) => {
        return (
          <Row key={item._id}>
            <Col xl={6} md={6}>
              <Controller
                render={({ onChange, onBlur, value, name, ref }) => (
                  <FormInput
                    name={`respondents[${index}].email`}
                    placeholder=" "
                    type="email"
                    label="Email"
                    inputClassName={`form-control value_${index}`}
                    defaultValue={''}
                    inputRef={register({ required: true })}
                  />
                )}
                name={`respondents[${index}].email`}
                defaultValue={''}
                inputRef={register({ required: true })}
                control={control}
              />
            </Col>
            <Col xl={1} md={1}>
              <label className="form-label"></label>
              <button
                className="btn btn-danger form-control"
                type="button"
                onClick={() => remove(index)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Col>
          </Row>
        );
      })}
      <section>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            append({
              column: '',
              type: '',
            });
          }}
        >
          Add user's email
        </button>
      </section>
    </>
  ) : (
    <Loader loading={true} />
  );
};

SendSurveyForm.defaultProps = {};
