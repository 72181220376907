import { CustomFields } from './Custumfields';
import FormInput from 'App/components/Forms/Input';
import { Loader } from 'App/components/Loader';
import { NameDescription } from './NameDescription';
import React from 'react';

export const SupplierForm = ({
  register,
  errors,
  values,
  control,
  setValue,
  controlId,
  ...props
}) => {
  React.useEffect(() => {
    if (values?.id) {
      register('id');
      setValue('id', values?.id);
    }
  }, [values, register, setValue]);
  return values ? (
    <>
      <NameDescription
        setValue={setValue}
        values={values}
        errors={errors}
        register={register}
      />
      <FormInput
        name="tel"
        defaultValue={values?.tel}
        inputRef={register({ required: false })}
        error={errors?.tel}
      />
      <FormInput
        name="email"
        defaultValue={values?.email}
        inputRef={register({ required: false })}
        error={errors?.email}
      />
      <FormInput
        name="street"
        defaultValue={values?.street}
        inputRef={register({ required: false })}
        error={errors?.street}
      />
      <FormInput
        name="zipCode"
        defaultValue={values?.zipCode}
        inputRef={register({ required: false })}
        error={errors?.zipCode}
      />
      <FormInput
        name="city"
        defaultValue={values?.city}
        inputRef={register({ required: false })}
        error={errors?.city}
      />
      <CustomFields
        register={register}
        errors={errors}
        control={control}
        values={values}
        fields={props?.customFields}
        setValue={setValue}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};
