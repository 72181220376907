import { gql } from '@apollo/client';

export const CreatePolicy = gql`
  mutation createPolicy(
    $title: String!
    $description: String
    $nextReview: ISO8601DateTime
    $standardId: ID
    $attribs: JSON
  ) {
    createPolicy(
      input: {
        params: {
          title: $title
          description: $description
          nextReview: $nextReview
          standardId: $standardId
          attribs: $attribs
        }
      }
    ) {
      policy {
        id
        title
        description
        attribs
        nextReview
        assignments {
          name
          users {
            id
            email
          }
        }
      }
    }
  }
`;

export const UpdatePolicy = gql`
  mutation updatePolicy(
    $id: ID!
    $title: String!
    $description: String
    $nextReview: ISO8601DateTime
    $standardId: ID
    $attribs: JSON
  ) {
    updatePolicy(
      input: {
        id: $id
        details: {
          title: $title
          description: $description
          nextReview: $nextReview
          standardId: $standardId
          attribs: $attribs
        }
      }
    ) {
      policy {
        id
        title
        description
        attribs
        nextReview
        assignments {
          name
          users {
            id
            email
          }
        }
      }
    }
  }
`;

export const DeletePolicy = gql`
  mutation deletePolicy($id: ID!) {
    deletePolicy(input: { id: $id }) {
      policy {
        id
      }
    }
  }
`;

export const AssignPolicy = gql`
  mutation assignPolicy($policyId: ID!, $type: Assigner!, $assignerId: Int!) {
    assignPolicy(
      input: {
        policyId: $policyId
        assignment: { assignerId: $assignerId, type: $type }
      }
    ) {
      policy {
        id
      }
    }
  }
`;
