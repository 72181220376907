import { gql } from '@apollo/client';

export const CreateSupplier = gql`
  mutation createSupplier(
    $title: String!
    $description: String!
    $tel: String
    $email: String
    $zipCode: String
    $street: String
    $city: String
    $attribs: JSON
  ) {
    createSupplier(
      input: {
        params: {
          title: $title
          description: $description
          attribs: $attribs
          tel: $tel
          email: $email
          street: $street
          zipCode: $zipCode
          city: $city
        }
      }
    ) {
      supplier {
        id
        title
        description
        attribs
        email
        zipCode
        city
        tel
        street
      }
    }
  }
`;

export const UpdateSupplier = gql`
  mutation updateSupplier(
    $id: ID!
    $title: String!
    $description: String!
    $tel: String
    $email: String
    $street: String
    $zipCode: String
    $city: String
    $attribs: JSON
  ) {
    updateSupplier(
      input: {
        id: $id
        params: {
          title: $title
          description: $description
          tel: $tel
          email: $email
          zipCode: $zipCode
          street: $street
          city: $city
          attribs: $attribs
        }
      }
    ) {
      supplier {
        id
        title
        description
        attribs
        email
        zipCode
        city
        tel
        street
      }
    }
  }
`;

export const DeleteSupplier = gql`
  mutation deleteSupplier($id: ID!) {
    deleteSupplier(input: { id: $id }) {
      supplier {
        id
      }
    }
  }
`;
export const DeleteCampaign = gql`
  mutation deleteCampaign($id: ID!) {
    deleteCampaign(input: { id: $id }) {
      campaign {
        id
      }
    }
  }
`;

export const InviteSurvey = gql`
  mutation inviteRespondents(
    $campaignId: ID!
    $respondents: [RespondentInput!]!
  ) {
    inviteRespondents(
      input: { params: { campaignId: $campaignId, respondents: $respondents } }
    ) {
      respondents {
        email
      }
    }
  }
`;

export const CreateSurvey = gql`
  mutation createSurvey($title: String, $jsonConfig: JSON!) {
    createSurvey(
      input: { params: { title: $title, jsonConfig: $jsonConfig } }
    ) {
      survey {
        id
        title
        jsonConfig
      }
    }
  }
`;

export const UpdateSurvey = gql`
  mutation updateSurvey($id: ID!, $title: String, $jsonConfig: JSON!) {
    updateSurvey(
      input: { id: $id, details: { title: $title, jsonConfig: $jsonConfig } }
    ) {
      survey {
        id
        title
        jsonConfig
      }
    }
  }
`;

export const CreateCampaign = gql`
  mutation createCampaign(
    $supplierExternalId: ID!
    $surveyId: ID!
    $status: Boolean
  ) {
    createCampaign(
      input: {
        params: {
          supplierExternalId: $supplierExternalId
          surveyId: $surveyId
          status: $status
        }
      }
    ) {
      campaign {
        id
        status
        survey {
          id
          title
        }
      }
    }
  }
`;

export const DeleteSurvey = gql`
  mutation deleteSurvey($id: ID!) {
    deleteSurvey(input: { id: $id }) {
      survey {
        id
      }
    }
  }
`;
