import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { Loader } from 'App/components/Loader';
import React from 'react';
import SelectGrouped from 'App/components/Forms/SelectGrouped';

export const CampaignForm = ({
  register,
  errors,
  values,
  control,
  setValue,
  supplierId,
  surveys,
  ...props
}) => {
  React.useEffect(() => {
    register('surveyId', { required: true });
    register('status');
    setValue('status', true);
    register('supplierExternalId', { required: true });
    if (values?.supplierId) setValue('supplierExternalId', values?.supplierId);
  }, [values, register, setValue]);

  return values ? (
    <>
      <SelectGrouped
        name="surveyId"
        label="Survey to be completed"
        defaultValue={values?.surveyId}
        error={errors?.surveyId}
        required
        groupedOptions={surveys}
        onChange={answer => setValue('surveyId', answer.value)}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};

CampaignForm.defaultProps = {};
