import { useFeature } from 'flagged';

const useCheckFeature = moduleName => {
  const trial = useFeature('trial');
  const moduleTesting = useFeature(`testing:${moduleName}`);
  const moduleTrial = useFeature(`trial:${moduleName}`);
  const testOrDevEnv =
    window.location.host.includes('testing') ||
    window.location.host.includes('localhost');
  let hide = false;
  if (moduleTrial && trial && !testOrDevEnv) hide = true;
  if (testOrDevEnv || moduleTesting) hide = false;
  if (!testOrDevEnv && moduleTesting) hide = true;

  return hide;
};

export { useCheckFeature };
