import { gql } from '@apollo/client';

export const GetStandards = gql`
  query standards {
    standards {
      id
      title
      description
      attribs
      standardClauses {
        id
        title
      }
    }
  }
`;

export const GetStandardClauses = gql`
  query standardClauses {
    standardClauses {
      id
      title
      description
      attribs
      standard {
        id
        title
      }
    }
  }
`;

export const GetGapAnalysisHistories = gql`
  query gapAnalysisHistory {
    gapAnalysisHistory {
      id
      name
      gap
      rundate
      standards {
        id
        title
      }
      controls {
        id
        title
      }
    }
  }
`;

export const GetGapAnalysisHistory = gql`
  query gapAnalysisDetail($id: ID!) {
    gapAnalysisDetail(id: $id) {
      gapAnalysis {
        id
        name
        gap
        rundate
        standards {
          id
          title
        }
        controls {
          id
          title
        }
      }
      inPolicyControls {
        id
        title
      }
      overlapControls {
        id
        title
        overlapStandardClauses {
          id
          title
          standard {
            id
            title
          }
        }
      }
      inStandardClauses {
        id
        title
        standard {
          id
          title
        }
      }
    }
  }
`;
export const GetStandardAndClauses = gql`
  query {
    me {
      company {
        standards {
          id
          title
          standardClauses {
            id
            title
          }
        }
      }
    }
  }
`;

export const GetChoosenStandardIds = gql`
  query {
    me {
      company {
        standards {
          id
          title
        }
      }
    }
  }
`;
