import FormInput from 'App/components/Forms/Input';
import React from 'react';

const DateInput = ({
  register,
  setValue,
  item,
  index,
  errorsFields,
  value,
}) => {
  const [startDate, setStartDate] = React.useState(new Date(value));
  React.useEffect(() => {
    register(`attribs[${index}][${item.name}]`, { required: item?.mandatory });
  }, [register, item, index]);
  return (
    <>
      <FormInput
        label={item.name}
        key={item.id}
        onChange={date => {
          setStartDate(date);
          setValue(`attribs[${index}][${item.name}]`, new Date(date));
        }}
        defaultValue={startDate}
        type="date"
        name={`attribs[${index}][${item.name}]`}
        placeholder=" "
        error={errorsFields[item.name]}
        required={item.mandatory}
      />
    </>
  );
};

const CKInput = ({ register, setValue, item, index, errorsFields, value }) => {
  const [content, setContent] = React.useState({
    description: value || '',
  });

  const handleQuillEdit = (c, delta, source, editor) => {
    setContent(prev => {
      return {
        ...prev,
        description: editor.getHTML(),
      };
    });
    setValue(`attribs[${index}][${item.name}]`, editor.getHTML());
  };

  React.useEffect(() => {
    register(`attribs[${index}][${item.name}]`, { required: item?.mandatory });
  }, [register, item, index]);
  return (
    <>
      <FormInput
        name={`attribs[${index}][${item.name}]`}
        label={item.name}
        type={'ckeditor'}
        placeholder=" "
        defaultValue={content.description}
        onChange={handleQuillEdit}
        error={errorsFields[item.name]}
        required={item.mandatory}
        inputRef={register({ required: item.mandatory })}
      />
    </>
  );
};
export const CustomFields = ({
  register,
  errors,
  values,
  fields,
  setValue,
}) => {
  const customFields = React.useRef(fields?.customFields);
  const errorsFields = {};

  React.useEffect(() => {
    const registered = [];
    let tIndex = 0;
    if (values && values.attribs) {
      values.attribs.forEach((item, index) => {
        const key = Object.keys(item)[0];
        register(`attribs[${index}][${key}]`);
        setValue(`attribs[${index}][${key}]`, item[key]);
        registered.push({ name: key, index: index });
        tIndex = index;
      });
      setValue('attribs', values.attribs);
    }
    if (customFields.current) {
      const results = customFields.current.filter(
        ({ name: id1 }) => !registered.some(({ name: id2 }) => id2 === id1),
      );

      results.forEach(field => {
        if (field.name === undefined) return;
        ++tIndex;
        register(`attribs[${tIndex}][${field.name}]`);
      });
    }
  }, [setValue, values, register, errors]);

  return customFields.current ? (
    <>
      {customFields.current.map((item, index) => {
        try {
          errorsFields[item.name] = errors?.attribs[index][item.name];
          errorsFields[item.name].message = 'This field is required';
        } catch (err) {}

        let fieldType = item?.value || 'text';
        let form;

        switch (item?.value) {
          case 'richText':
            let value = null;
            values?.attribs &&
            values.attribs[index] &&
            values.attribs[index][item.name]
              ? (value = values.attribs[index][item.name])
              : (value = '');
            form = (
              <CKInput
                register={register}
                setValue={setValue}
                key={item.id}
                item={item}
                index={index}
                errorsFields={errorsFields}
                value={value}
              />
            );
            break;
          case 'date':
            let dateValue = new Date();
            if (
              values?.attribs &&
              values.attribs[index] &&
              values.attribs[index][item.name]
            ) {
              Date.parse(values.attribs[index][item.name])
                ? (dateValue = values.attribs[index][item.name])
                : new Date();
            }
            form = (
              <DateInput
                register={register}
                setValue={setValue}
                key={item.id}
                item={item}
                index={index}
                errorsFields={errorsFields}
                value={dateValue}
              />
            );
            break;

          default:
            form = (
              <FormInput
                name={`attribs[${index}][${item.name}]`}
                label={item.name}
                type={fieldType}
                placeholder=" "
                defaultValue={
                  values?.attribs &&
                  values.attribs[index] &&
                  values.attribs[index][item.name]
                    ? values.attribs[index][item.name]
                    : null
                }
                inputRef={register({ required: item.mandatory })}
                error={errorsFields[item.name]}
                required={item.mandatory}
              />
            );
            break;
        }

        return <div key={item.id}>{form}</div>;
      })}
    </>
  ) : (
    <></>
  );
};
