import { gql } from '@apollo/client';

export const CreateIncident = gql`
  mutation createIncident(
    $title: String!
    $description: String!
    $nextSteps: String!
    $nextUpdateTime: ISO8601DateTime
    $pta: String
    $riskRating: String
    $status: String!
    $attribs: JSON
  ) {
    createIncident(
      input: {
        params: {
          title: $title
          description: $description
          nextSteps: $nextSteps
          riskRating: $riskRating
          nextUpdateTime: $nextUpdateTime
          pta: $pta
          attribs: $attribs
          status: $status
        }
      }
    ) {
      incident {
        id
      }
    }
  }
`;

export const UpdateIncident = gql`
  mutation updateIncident(
    $id: ID!
    $title: String!
    $description: String!
    $nextSteps: String!
    $nextUpdateTime: ISO8601DateTime
    $status: String!
    $pta: String
    $riskRating: String
    $attribs: JSON
  ) {
    updateIncident(
      input: {
        id: $id
        details: {
          title: $title
          description: $description
          riskRating: $riskRating
          nextUpdateTime: $nextUpdateTime
          nextSteps: $nextSteps
          pta: $pta
          status: $status
          attribs: $attribs
        }
      }
    ) {
      incident {
        id
      }
    }
  }
`;

export const DeleteIncident = gql`
  mutation deleteIncident($id: ID!) {
    deleteIncident(input: { id: $id }) {
      incident {
        id
      }
    }
  }
`;

export const CreateComment = gql`
  mutation addCommentToIncident($comment: String!, $incidentId: ID!) {
    addCommentToIncident(
      input: { comment: $comment, incidentId: $incidentId }
    ) {
      incident {
        id
      }
    }
  }
`;
