import React, { useState } from 'react';

export const UserPreferencesContext = React.createContext({
  data: {},
  setData: () => {},
});

export const UserPreferencesContextProvider = props => {
  const setData = data => {
    setState({ ...state, data: data });
  };

  const initState = {
    data: {},
    setData: setData,
  };

  const [state, setState] = useState(initState);

  return (
    <UserPreferencesContext.Provider value={state}>
      {props.children}
    </UserPreferencesContext.Provider>
  );
};
