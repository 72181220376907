import FormInput from 'App/components/Forms/Input';
import React from 'react';

export const NameDescription = ({
  register,
  values,
  errors,
  setValue,
  getValues,
}) => {
  React.useEffect(() => {
    register('description', { required: true });
  }, [register, setValue, values]);

  const [content, setContent] = React.useState({
    description: values?.description || '',
  });

  const handleQuillEdit = (c, delta, source, editor) => {
    setContent(prev => {
      return {
        ...prev,
        description: editor.getHTML(),
      };
    });
  };
  React.useEffect(() => {
    setValue('description', content.description);
  }, [setValue, content, getValues]);

  return (
    <>
      <FormInput
        name="title"
        defaultValue={values?.title}
        inputRef={register({ required: true })}
        required
        error={errors?.title}
      />
      <FormInput
        name="description"
        type="ckeditor"
        required
        inputRef={register({ required: true })}
        defaultValue={content.description}
        onChange={handleQuillEdit}
        error={errors?.description}
      />
    </>
  );
};
