import Dialog from 'react-bootstrap-dialog';
import React from 'react';
import useCustomFormSubmit from 'App/hooks/submit-hook';
export const useHasDelete = ({ query, refetch }) => {
  const dialog = React.useRef(null);
  const { customHandleSubmit: deleteFunction, resultOk } = useCustomFormSubmit(
    query,
    refetch,
  );
  Dialog.setOptions({
    defaultOkLabel: 'Yes',
    primaryClassName: 'btn-danger',
  });
  const onDelete = React.useCallback(
    id => {
      let params = { id: id };
      if (typeof id == 'object') params = id;
      dialog.current.show({
        title: 'Alert',
        body: 'Do you want to delete this resource ?',
        actions: [
          Dialog.CancelAction(),
          Dialog.OKAction(() => {
            deleteFunction(params);
          }),
        ],
        bsSize: 'small',
      });
    },
    [deleteFunction],
  );

  return {
    dialog: dialog,
    onDelete: onDelete,
    result: resultOk,
  };
};

export default useHasDelete;
