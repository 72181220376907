import React from 'react';
import { useContextMenu } from 'react-contexify';

export const useHasContextualMenu = props => {
  const [modalAssign, setModalAssign] = React.useState(false);
  const { show } = useContextMenu({
    id: props.menu_id,
  });

  const handleContextMenu = React.useCallback(
    event => {
      event.preventDefault();
      const prop = { id: event.currentTarget.id };
      if (event.currentTarget?.getAttribute('data'))
        prop['data'] = event.currentTarget.getAttribute('data');
      show(event, {
        props: prop,
      });
    },
    [show],
  );

  const handleItemClick = ({ event, props }) => {
    switch (event.currentTarget.id) {
      case 'assign':
        setModalAssign(true);
        break;

      default:
    }
  };

  return {
    handleItemClick: handleItemClick,
    handleContextMenu: handleContextMenu,
    modalAssign: modalAssign,
    setModalAssign: setModalAssign,
  };
};
