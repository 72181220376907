import { CustomFields } from './Custumfields';
import { FindingMatrixForm } from './FindingMatrixForm';
import { Loader } from 'App/components/Loader';
import { NameDescription } from './NameDescription';
import React from 'react';
import SelectGrouped from 'App/components/Forms/SelectGrouped';

export const FindingForm = ({
  register,
  errors,
  values,
  control,
  setValue,
  getValues,
  auditId,
  matrixes,
  unregister,
  likelihoods,
  riskRegisters,
  impacts,
  ...props
}) => {
  const statusList = [
    { label: 'Open', value: 'open' },
    { label: 'In progress', value: 'in_progress' },
    { label: 'Close', value: 'close' },
  ];
  React.useEffect(() => {
    register('riskMatrixId', { required: true });
    setValue('riskMatrixId', values?.riskMatrix?.id);

    if (values?.id) {
      register('id');
      setValue('id', values?.id);
    }
    register('auditId', { required: true });
    register('status', { required: false });
    setValue('auditId', auditId);
  }, [register, values, setValue, auditId]);

  return values ? (
    <>
      <NameDescription
        setValue={setValue}
        values={values}
        errors={errors}
        register={register}
      />

      <FindingMatrixForm
        values={values}
        register={register}
        unregister={unregister}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        impacts={impacts}
        riskRegisters={riskRegisters}
        likelihoods={likelihoods}
        matrixes={matrixes}
      />

      <SelectGrouped
        name="status"
        label="Status"
        defaultValue={values?.status}
        error={errors?.status}
        groupedOptions={statusList}
        onChange={answer => setValue('status', answer.value)}
      />

      <CustomFields
        register={register}
        errors={errors}
        control={control}
        values={values}
        fields={props?.customFields}
        setValue={setValue}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};
