import { gql } from '@apollo/client';

export const CreateCustomField = gql`
  mutation createCustomField(
    $name: String!
    $value: String
    $mandatory: Boolean
    $customizable: Object
  ) {
    createCustomField(
      input: {
        params: {
          name: $name
          value: $value
          mandatory: $mandatory
          customizable: $customizable
        }
      }
    ) {
      customField {
        id
      }
    }
  }
`;

export const UpdateCustomField = gql`
  mutation updateCustomField(
    $id: ID!
    $name: String!
    $value: String
    $mandatory: Boolean
  ) {
    updateCustomField(
      input: {
        id: $id
        details: { name: $name, value: $value, mandatory: $mandatory }
      }
    ) {
      customField {
        id
      }
    }
  }
`;

export const DeleteCustomField = gql`
  mutation deleteCustomField($id: ID!) {
    deleteCustomField(input: { id: $id }) {
      customField {
        id
      }
    }
  }
`;

export const AssignObject = gql`
  mutation assignObject(
    $recordId: ID!
    $type: Assigner!
    $assignerId: Int!
    $objectType: Object!
  ) {
    assignObject(
      input: {
        recordId: $recordId
        assignment: { assignerId: $assignerId, type: $type }
        objectType: $objectType
      }
    ) {
      objectAssignment {
        recordId
      }
    }
  }
`;

export const CreateChartconfig = gql`
  mutation createChartConfig($sortOrder: Int!, $config: String!) {
    createChartConfig(
      input: { params: { sortOrder: $sortOrder, config: $config } }
    ) {
      chartConfig {
        id
      }
    }
  }
`;

export const DeleteChartConfig = gql`
  mutation deleteChartConfig($id: ID!) {
    deleteChartConfig(input: { id: $id }) {
      chartConfig {
        id
      }
    }
  }
`;
export const CreateWorkflow = gql`
  mutation createWorkflow($controlTestIds: [ID!], $data: String!) {
    createWorkflow(
      input: { params: { controlTestIds: $controlTestIds, data: $data } }
    ) {
      workflow {
        id
        realmName
      }
    }
  }
`;

export const UpdateWorkflow = gql`
  mutation UpdateWorkflow(
    $id: ID!
    $data: String
    $name: String
    $schedule: String
    $special: Boolean
    $nextExecutionTime: ISO8601DateTime
    $lastPickedupTime: ISO8601DateTime
  ) {
    updateWorkflow(
      input: {
        id: $id
        details: {
          name: $name
          data: $data
          schedule: $schedule
          special: $special
          nextExecutionTime: $nextExecutionTime
          lastPickedupTime: $lastPickedupTime
        }
      }
    ) {
      workflow {
        id
      }
    }
  }
`;
