import { gql } from '@apollo/client';

export const CreateRiskRegister = gql`
  mutation createRiskRegister(
    $title: String!
    $description: String
    $attribs: JSON
  ) {
    createRiskRegister(
      input: {
        params: { title: $title, description: $description, attribs: $attribs }
      }
    ) {
      riskRegister {
        id
      }
    }
  }
`;

export const UpdateRiskRegister = gql`
  mutation updateRiskRegister(
    $id: ID!
    $title: String!
    $description: String
    $attribs: JSON
  ) {
    updateRiskRegister(
      input: {
        id: $id
        details: { title: $title, description: $description, attribs: $attribs }
      }
    ) {
      riskRegister {
        id
      }
    }
  }
`;

export const DeleteRiskRegister = gql`
  mutation deleteRiskRegister($id: ID!) {
    deleteRiskRegister(input: { id: $id }) {
      riskRegister {
        id
      }
    }
  }
`;

export const CreateRisk = gql`
  mutation createRisk(
    $title: String!
    $description: String
    $riskRegisterId: ID!
    $likelihoodRatingId: ID
    $impactRatingId: ID
    $attribs: JSON
  ) {
    createRisk(
      input: {
        params: {
          title: $title
          description: $description
          riskRegisterId: $riskRegisterId
          likelihoodRatingId: $likelihoodRatingId
          attribs: $attribs
          impactRatingId: $impactRatingId
        }
      }
    ) {
      risk {
        id
      }
    }
  }
`;

export const UpdateRisk = gql`
  mutation updateRisk(
    $id: ID!
    $title: String!
    $description: String
    $likelihoodRatingId: ID
    $impactRatingId: ID
    $attribs: JSON
  ) {
    updateRisk(
      input: {
        id: $id
        details: {
          title: $title
          description: $description
          attribs: $attribs
          likelihoodRatingId: $likelihoodRatingId
          impactRatingId: $impactRatingId
        }
      }
    ) {
      risk {
        id
      }
    }
  }
`;

export const DeleteRisk = gql`
  mutation deleteRisk($id: ID!) {
    deleteRisk(input: { id: $id }) {
      risk {
        id
      }
    }
  }
`;
