import { gql } from '@apollo/client';

export const GetRiskRegisters = gql`
  query riskRegisters {
    riskRegisters {
      id
      title
      description
      attribs
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetRisks = gql`
  query risks($riskRegisterId: ID) {
    risks(riskRegisterId: $riskRegisterId) {
      id
      title
      attribs
      description
      likelihoodRating {
        id
        title
        attribs
      }
      impactRating {
        id
        title
        attribs
      }
      riskMatrix {
        title
        color
      }
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetPolicy = gql`
  query policy($id: ID!) {
    policy(id: $id) {
      id
      title
      description
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetRiskRegister = gql`
  query riskRegister($id: ID!) {
    riskRegister(id: $id) {
      id
      title
      description
      likelihoodRatingIds
      impactRatingIds
      attribs
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetRisk = gql`
  query risk($id: ID!) {
    risk(id: $id) {
      id
      title
      description
      attribs
      likelihoodRating {
        id
        title
      }
      impactRating {
        id
        title
      }
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetRiskMatrixes = gql`
  query riskMatrixes {
    riskMatrixes {
      id
      title
      color
      likelihoodRating {
        title
      }
      impactRating {
        title
      }
      riskRegister {
        title
      }
    }
  }
`;
