import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './node-style.css';

import { Col, Row } from 'react-bootstrap';

import { CustomFields } from './Custumfields';
import FormInput from 'App/components/Forms/Input';
import { Link } from 'react-router-dom';
import { Loader } from 'App/components/Loader';
import NodeTree from 'App/components/Forms/NodeTree';
import React from 'react';
import messages from 'messages';

export const StandardForm = ({
  register,
  errors,
  values,
  control,
  setValue,
  intl,
  ...props
}) => {
  const [checkedClauses, setCheckedClauses] = React.useState([]);
  const [checkedControls, setCheckedControls] = React.useState([]);
  const choosenStandard = React.useRef([]),
    choosenControl = React.useRef([]);

  React.useEffect(() => {
    register('standardIds');
    register('controlIds');
    register('rundate');
    setValue('rundate', new Date());
    if (values.startDate) setValue('rundate', values?.rundate);
    if (values.controls) {
      choosenControl.current = values?.controls?.map(item => item.id);
      setValue('controlIds', choosenControl.current);
      setCheckedControls(choosenControl.current);
    }
    if (values.standardClauses) {
      choosenStandard.current = values?.standardClauses?.map(item => item.id);
      setValue('standardIds', choosenStandard.current);
      setCheckedClauses(choosenStandard.current);
    }
  }, [
    register,
    setValue,
    values,
    setCheckedControls,
    choosenControl,
    choosenStandard,
  ]);

  const standardCheck = (e, typeArray = 'standard') => {
    if (typeArray === 'standard') {
      choosenStandard.current = e;
      setValue('standardIds', choosenStandard.current);
    }
    if (typeArray === 'control') {
      choosenControl.current = e;
      setValue('controlIds', choosenControl.current);
    }
  };

  const controlsTree = props?.controls?.map(item => {
    let tree = {
      label: item.title,
      value: item.id + item.title,
      name: item.title,
      children: [],
    };
    tree.children = item?.controls?.map(ctl => {
      return { value: ctl.id, label: ctl.title, name: item.title };
    });
    return tree;
  });

  const standardTree = props?.standardClauses?.map(item => {
    let tree = {
      label: item.title,
      value: item.id,
      // children: [],
    };

    return tree;
  });

  return values ? (
    <>
      {values?.id && (
        <input type="hidden" ref={register()} name="id" value={values?.id} />
      )}
      <FormInput
        name="name"
        label={intl.formatMessage(messages.Name)}
        defaultValue={values.name}
        inputRef={register({ required: true })}
        required
        error={errors?.name}
      />

      <Row>
        <Col md={6} className="checkNodes controls-cy">
          {props?.controls?.length > 0 ? (
            <NodeTree
              name="controlTree"
              expandOnClick
              label={intl.formatMessage(messages.ControlsToInclude)}
              nodes={controlsTree}
              checked={checkedControls}
              onCheck={check => {
                setCheckedControls([...check]);
                standardCheck(check, 'control');
              }}
            />
          ) : (
            <Loader loading />
          )}
        </Col>
        <Col md={6} className="checkNodes strandards-cy">
          {props?.standardClauses?.length > 0 && standardTree?.length > 0 ? (
            <NodeTree
              name="standardTree"
              expandOnClick
              label={intl.formatMessage(messages.StandardToInclude)}
              nodes={standardTree}
              checked={checkedClauses}
              onCheck={check => {
                setCheckedClauses([...check]);
                standardCheck(check);
              }}
            />
          ) : (
            <div className="text-center mt-5 h6">
              Select a list of interested standards in the{' '}
              <Link to="/standards">Security Standards configuration</Link>
            </div>
          )}
        </Col>
      </Row>

      <CustomFields
        register={register}
        errors={errors}
        control={control}
        values={values}
        fields={props?.customFields}
        setValue={setValue}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};
