import { gql } from '@apollo/client';

export const UserCreation = gql`
  mutation createUser($email: String!) {
    createUser(input: { params: { email: $email } }) {
      user {
        id
      }
    }
  }
`;

export const UpdateUser = gql`
  mutation updateUser($id: ID!, $email: String!) {
    updateUser(input: { id: $id, email: $email }) {
      user {
        id
      }
    }
  }
`;

export const DeleteUser = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(input: { id: $id }) {
      user {
        id
      }
    }
  }
`;

export const UpdateUserPreferences = gql`
  mutation updateUserPreferences($details: JSON) {
    updateUserPreferences(input: { details: $details }) {
      user {
        id
        preferences
      }
    }
  }
`;
