import { gql } from '@apollo/client';

export const GetControls = gql`
  query controls($policyId: ID) {
    controls(policyId: $policyId) {
      id
      title
      description
      attribs
      standardClauses {
        id
        title
      }
      risks {
        id
        title
      }
      maturityScore {
        id
        title
      }
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetControl = gql`
  query control($id: ID!) {
    control(id: $id) {
      id
      title
      description
      attribs
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;
export const GetControlTests = gql`
  query controlTests($controlId: ID) {
    controlTests(controlId: $controlId) {
      id
      title
      description
      attribs
    }
  }
`;

export const GetControlTest = gql`
  query controlTest($id: ID!) {
    controlTest(id: $id) {
      id
      title
      description
      attribs
      workflows {
        id
        realmName
        data
        name
        special
        schedule
        nextExecutionTime
        lastPickedupTime
      }
    }
  }
`;

export const GetControlTestWithResults = gql`
  query controlTest($id: ID!) {
    controlTest(id: $id) {
      id
      title
      description
      attribs
      control {
        id
        policy {
          id
        }
      }
      controlTestResults {
        id
        title
        attribs
        createdAt
        endResultData
        endResultDataType
      }
    }
  }
`;
export const GetControlTestResults = gql`
  query controlTestResults($controlTestId: ID) {
    controlTestResults(controlTestId: $controlTestId) {
      id
      title
      description
      attribs
      createdAt
      endResultData
      endResultDataType
    }
  }
`;

export const GetControlTestResult = gql`
  query controlTestResult($id: ID!) {
    controlTestResult(id: $id) {
      id
      title
      description
      attribs
      createdAt
      endResultData
      endResultDataType
    }
  }
`;
