import { CustomFields } from './Custumfields';
import FormInput from 'App/components/Forms/Input';
import { Loader } from 'App/components/Loader';
import { NameDescription } from './NameDescription';
import React from 'react';

export const PolicyForm = ({
  register,
  errors,
  control,
  customFields,
  values,
  getValues,
  setValue,
  ...props
}) => {
  const [startDate, setStartDate] = React.useState();
  React.useEffect(() => {
    register('nextReview', { required: false });
    if (values?.nextReview) {
      setStartDate(new Date(values?.nextReview));
      setValue('nextReview', values?.nextReview);
    }

    if (values?.id) {
      register('id');
      setValue('id', values?.id);
    }
  }, [register, values, setValue]);
  return values ? (
    <>
      <NameDescription
        setValue={setValue}
        values={values}
        getValues={getValues}
        errors={errors}
        register={register}
      />
      <FormInput
        name="nextReview"
        type="date"
        defaultValue={startDate}
        error={errors?.nextReview}
        onChange={date => {
          setStartDate(date);
          setValue('nextReview', new Date(date));
        }}
      />
      <FormInput
        name="scope"
        inputRef={register({ required: false })}
        defaultValue={values?.scope}
        error={errors?.scope}
      />
      <CustomFields
        fields={customFields}
        register={register}
        errors={errors}
        control={control}
        values={values}
        setValue={setValue}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};
