import BaseSelect from 'react-select';
import FixRequiredSelect from './FixRequiredSelect';
import PropTypes from 'prop-types';
import React from 'react';
const formatGroupLabel = data => (
  <div>
    <span>{data.label}</span>
  </div>
);

const SelectRequired = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

const SelectGrouped = ({
  name,
  label,
  error,
  groupedOptions,
  isMulti,
  onChange,
  defaultValue,
  labelClassName,
  required,
  smallText,
  ...props
}) => {
  let value = defaultValue;
  if (!isMulti && defaultValue) {
    value = groupedOptions?.filter(option => option.value === defaultValue);
  }
  if (isMulti && defaultValue) {
    value = groupedOptions?.filter(option =>
      defaultValue.find(i => i.id === option.value),
    );
  }
  return (
    <div className="form-group">
      <label htmlFor={name} className={labelClassName}>
        {label ? label : name} {required ? '*' : ''}
      </label>
      <SelectRequired
        defaultValue={value}
        options={groupedOptions}
        isMulti={isMulti}
        aria-label={name}
        classNamePrefix={props?.inputClassName || name}
        name={name}
        onChange={onChange}
        formatGroupLabel={formatGroupLabel}
      />
      {error && error.type === 'required' && (
        <p style={{ color: 'red' }}>This field is required</p>
      )}
      {error && error.type !== 'required' && (
        <p style={{ color: 'red' }}>{error.message}</p>
      )}
      {smallText && <small className="text-muted form-text">{smallText}</small>}
    </div>
  );
};

SelectGrouped.defaultProps = {
  labelClassName: 'form-label',
  isMulti: false,
  required: false,
  defaultValue: [],
};

SelectGrouped.propTypes = {
  name: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  groupedOptions: PropTypes.array,
  isMulti: PropTypes.bool,
};
export default SelectGrouped;
