import { gql } from '@apollo/client';

export const CreateMaturityScore = gql`
  mutation createMaturityScore(
    $title: String!
    $description: String
    $attribs: JSON
  ) {
    createMaturityScore(
      input: {
        params: { title: $title, description: $description, attribs: $attribs }
      }
    ) {
      maturityScore {
        id
      }
    }
  }
`;

export const UpdateMaturityScore = gql`
  mutation updateMaturityScore(
    $id: ID!
    $title: String!
    $description: String
    $attribs: JSON
  ) {
    updateMaturityScore(
      input: {
        id: $id
        details: { title: $title, description: $description, attribs: $attribs }
      }
    ) {
      maturityScore {
        id
      }
    }
  }
`;

export const DeleteMaturityScore = gql`
  mutation deleteMaturityScore($id: ID!) {
    deleteMaturityScore(input: { id: $id }) {
      maturityScore {
        id
      }
    }
  }
`;
