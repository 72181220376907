import { gql } from '@apollo/client';

export const AddDocumentToRepository = gql`
  mutation addDocumentToRepository($id: ID!, $document: DirectUploadInput!) {
    addDocumentToRepository(input: { id: $id, document: $document }) {
      directUpload {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`;

export const CreateRepository = gql`
  mutation createCompanyRepository($title: String!, $companyId: ID!) {
    createCompanyRepository(
      input: { params: { title: $title, companyId: $companyId } }
    ) {
      repository {
        id
      }
    }
  }
`;
export const DeleteRepository = gql`
  mutation deleteRepository($id: ID!) {
    deleteRepository(input: { id: $id }) {
      repository {
        id
      }
    }
  }
`;

export const DeleteRepositoryDocument = gql`
  mutation deleteRepositoryDocument($id: String!) {
    deleteRepositoryDocument(input: { id: $id }) {
      id
    }
  }
`;
