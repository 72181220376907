import FormInput from 'App/components/Forms/Input';
import { Loader } from 'App/components/Loader';
import React from 'react';

export const UserForm = ({
  register,
  errors,
  control,
  values,
  setValue,
  riskRegisterId,
  impacts,
  likelihoods,
  ...props
}) => {
  React.useEffect(() => {
    if (values?.id) {
      register('id');
      setValue('id', values?.id);
    }
  }, [register, setValue, values]);

  setValue('riskRegisterId', riskRegisterId);

  return values ? (
    <>
      <FormInput
        name="email"
        defaultValue={values?.email}
        inputRef={register({ required: true })}
        required
        error={errors?.email}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};
