import './predefined_color.css';

import { Col, Row } from 'react-bootstrap';

import FormInput from 'App/components/Forms/Input';
import InputColor from 'react-input-color';
import { Loader } from 'App/components/Loader';
import PropTypes from 'prop-types';
import React from 'react';

export const RiskRatingForm = ({
  register,
  errors,
  control,
  values,
  setValue,
  watch,
  ...props
}) => {
  const data = values;
  const [color, setColor] = React.useState({});
  React.useEffect(() => {
    register('color', { required: true });
    register('riskRegisterId', { required: true });
    register('likelihoodRatingId', { required: true });
    register('impactRatingId', { required: true });
    setValue('riskRegisterId', parseInt(data.riskRegisterId));
    setValue('likelihoodRatingId', parseInt(data.likelihoodId));
    setValue('impactRatingId', parseInt(data.impactId));
  }, [register, setValue, values, watch, data, props]);

  return values ? (
    <>
      <FormInput
        name="title"
        required
        inputRef={register({ required: true })}
        defaultValue={data?.title}
        error={errors?.title}
      />
      <div className="form-group">
        <label className="form-label">Choose a color</label>
        <div className="form-control">
          <InputColor
            initialValue={data?.color || '#fff'}
            onChange={clr => {
              setColor(clr);
              setValue('color', String(clr.hex));
            }}
            placement="right"
          />
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">Or choose a predefined color</label>
        <Row>
          <Col>
            <span
              className="color-red"
              onClick={() => {
                setValue('color', '#f54040d9');
                setColor({ hex: '#f54040d9' });
              }}
            />
          </Col>
          <Col>
            <span
              className="color-green"
              onClick={() => {
                setValue('color', '#7cf540ba');
                setColor({ hex: '#7cf540ba' });
              }}
            />
          </Col>
          <Col>
            <span
              className="color-warning"
              onClick={() => {
                setValue('color', '#f5a640eb');
                setColor({ hex: '#f5a640eb' });
              }}
            />
          </Col>
        </Row>
      </div>
      <div style={{ backgroundColor: color?.hex, color: '#fff' }}>
        Selected color: {color?.hex}
      </div>
    </>
  ) : (
    <Loader loading={true} />
  );
};

RiskRatingForm.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};
