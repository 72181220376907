import { CustomFields } from './Custumfields';
import { Loader } from 'App/components/Loader';
import { NameDescription } from './NameDescription';
import PropTypes from 'prop-types';
import React from 'react';
import SelectGrouped from 'App/components/Forms/SelectGrouped';

export const RiskForm = ({
  register,
  errors,
  control,
  values,
  setValue,
  riskRegisterId,
  impacts,
  likelihoods,
  customFields,
  ...props
}) => {
  React.useEffect(() => {
    register('impactRatingId', { required: true });
    register('riskRegisterId', { required: true });
    register('likelihoodRatingId', { required: true });
    setValue('impactRatingId', values?.impactRating?.id);
    setValue('likelihoodRatingId', values?.likelihoodRating?.id);
    setValue('riskRegisterId', riskRegisterId);
    if (values?.id) {
      register('id');
      setValue('id', values?.id);
    }
  }, [register, setValue, riskRegisterId, values]);

  return values ? (
    <>
      <NameDescription
        setValue={setValue}
        values={values}
        errors={errors}
        register={register}
      />

      <SelectGrouped
        name="likelihoodId"
        label="Likelihood"
        required
        defaultValue={values?.likelihoodRating?.id}
        error={errors?.likelihoodRatingId}
        groupedOptions={likelihoods.map(item => {
          return { label: item.title, value: item.id };
        })}
        onChange={answer => {
          setValue('likelihoodRatingId', answer.value);
        }}
      />
      <SelectGrouped
        name="impact"
        label="Impact"
        required
        defaultValue={values?.impactRating?.id}
        error={errors?.impactRatingId}
        groupedOptions={impacts.map(item => {
          return { label: item.title, value: item.id };
        })}
        onChange={answer => setValue('impactRatingId', answer.value)}
      />

      <CustomFields
        register={register}
        errors={errors}
        control={control}
        values={values}
        fields={customFields}
        setValue={setValue}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};

RiskForm.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  riskRegisterId: PropTypes.string.isRequired,
  impacts: PropTypes.array.isRequired,
  likelihoods: PropTypes.array.isRequired,
};
