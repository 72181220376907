import { gql } from '@apollo/client';

export const CreateControl = gql`
  mutation createControl(
    $title: String!
    $description: String
    $policyId: ID!
    $standardClauseIds: [ID!]
    $riskId: ID
    $maturityScoreId: ID
    $attribs: JSON
  ) {
    createControl(
      input: {
        params: {
          title: $title
          description: $description
          policyId: $policyId
          standardClauseIds: $standardClauseIds
          riskId: $riskId
          maturityScoreId: $maturityScoreId
          attribs: $attribs
        }
      }
    ) {
      control {
        id
      }
    }
  }
`;

export const UpdateControl = gql`
  mutation updateControl(
    $id: ID!
    $title: String!
    $description: String
    $standardClauseIds: [ID!]
    $riskId: ID
    $maturityScoreId: ID
    $attribs: JSON
  ) {
    updateControl(
      input: {
        id: $id
        details: {
          title: $title
          description: $description
          standardClauseIds: $standardClauseIds
          riskId: $riskId
          maturityScoreId: $maturityScoreId
          attribs: $attribs
        }
      }
    ) {
      control {
        id
      }
    }
  }
`;

export const AssignControl = gql`
  mutation assignControl($controlId: ID!, $type: String!, $assignerId: ID!) {
    assignControl(
      input: {
        controlId: $controlId
        assignment: { assignerId: $assignerId, type: $type }
      }
    ) {
      control {
        id
      }
    }
  }
`;

export const CreateControlTest = gql`
  mutation createControlTest(
    $title: String!
    $description: String
    $controlId: ID!
    $attribs: JSON
  ) {
    createControlTest(
      input: {
        params: {
          title: $title
          description: $description
          controlId: $controlId
          attribs: $attribs
        }
      }
    ) {
      controlTest {
        id
      }
    }
  }
`;

export const UpdateControlTest = gql`
  mutation updateControlTest(
    $id: ID!
    $title: String!
    $description: String
    $attribs: JSON
  ) {
    updateControlTest(
      input: {
        id: $id
        details: { title: $title, description: $description, attribs: $attribs }
      }
    ) {
      controlTest {
        id
      }
    }
  }
`;

export const CreateControlTestResult = gql`
  mutation createControlTestResult(
    $title: String!
    $description: String
    $controlTestId: ID!
    $attribs: JSON
  ) {
    createControlTestResult(
      input: {
        params: {
          title: $title
          description: $description
          controlTestId: $controlTestId
          attribs: $attribs
        }
      }
    ) {
      controlTestResult {
        id
      }
    }
  }
`;

export const UpdateControlTestResult = gql`
  mutation UpdateControlTestResult(
    $id: ID!
    $title: String!
    $description: String
    $attribs: JSON
  ) {
    updateControlTestResult(
      input: {
        id: $id
        details: { title: $title, description: $description, attribs: $attribs }
      }
    ) {
      controlTestResult {
        id
      }
    }
  }
`;

export const DeleteControl = gql`
  mutation deleteControl($id: ID!) {
    deleteControl(input: { id: $id }) {
      control {
        id
      }
    }
  }
`;

export const DeleteControlTest = gql`
  mutation deleteControlTest($id: ID!) {
    deleteControlTest(input: { id: $id }) {
      controlTest {
        id
      }
    }
  }
`;

export const DeleteControlTestResult = gql`
  mutation deleteControlTestResult($id: ID!) {
    deleteControlTestResult(input: { id: $id }) {
      controlTestResult {
        id
      }
    }
  }
`;
