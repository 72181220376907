import { Button, Form } from 'react-bootstrap';

import { Loader } from 'App/components/Loader';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

const GdprForm = ({
  submit,
  cancel,
  submitButtonLabel,
  cancelButtonLabel,
  inputs,
  formClasses,
  loading,
  ...props
}) => {
  return (
    <>
      <Form className={formClasses} onSubmit={submit}>
        {inputs && inputs()}
        <Modal.Footer>
          <Loader loading={loading ? true : false} />
          <Button
            variant="primary"
            type="submit"
            disabled={loading}
            data-cy="submit"
          >
            {submitButtonLabel}
          </Button>
          <Button
            variant="secondary"
            onClick={props.onHide}
            disabled={loading}
            data-cy="cancel"
          >
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

GdprForm.defaultProps = {
  submitButtonLabel: 'Submit',
};

GdprForm.propTypes = {
  submit: PropTypes.func.isRequired,
  inputs: PropTypes.func.isRequired,
  formClasses: PropTypes.string,
  loading: PropTypes.string,
};
export default GdprForm;
