import CreatableSingle from 'App/components/Forms/Creatable';
import { CustomFields } from './Custumfields';
import FormInput from 'App/components/Forms/Input';
import { Loader } from 'App/components/Loader';
import { NameDescription } from './NameDescription';
import React from 'react';
import SelectGrouped from 'App/components/Forms/SelectGrouped';

export const IncidentForm = ({
  register,
  errors,
  control,
  values,
  setValue,
  ...props
}) => {
  const [nextUpdateTime, setNextUpdateTime] = React.useState(
    values?.nextUpdateTime ? Date.parse(values?.nextUpdateTime) : null,
  );
  const riskRatings = React.useMemo(
    () => [
      { label: 'High', value: 'high' },
      { label: 'Medium', value: 'medium' },
      { label: 'Low', value: 'low' },
    ],
    [],
  );
  const statusList = React.useMemo(
    () => [
      { label: 'Open', value: 'open' },
      { label: 'Close', value: 'close' },
      { label: 'Ongoing', value: 'ongoing' },
    ],
    [],
  );

  React.useEffect(() => {
    register('status', { required: true });
    register('nextUpdateTime');
    register('riskRating');
    if (values?.id) {
      register('id');
      setValue('id', values?.id);
    }
    if (values?.status) {
      const exist = statusList.find(item => item.value === values.status);
      setValue('status', values.status);
      if (!exist)
        statusList.push({ label: values.status, value: values.status });
    }
  }, [register, setValue, values, statusList]);

  return values ? (
    <>
      <NameDescription
        setValue={setValue}
        values={values}
        errors={errors}
        register={register}
      />

      <FormInput
        name="nextSteps"
        inputRef={register({ required: false })}
        defaultValue={values?.nextSteps}
        error={errors?.nextSteps}
      />
      <FormInput
        name="nextUpdateTime"
        inputRef={register({ required: false })}
        type="datetime"
        defaultValue={nextUpdateTime}
        error={errors?.nextUpdateTime}
        onChange={date => {
          setNextUpdateTime(date);
          setValue('nextUpdateTime', date);
        }}
      />

      <SelectGrouped
        name="riskRating"
        label="Risk rating"
        defaultValue={values?.riskRating}
        error={errors?.riskRating}
        groupedOptions={riskRatings}
        onChange={answer => setValue('riskRating', answer.value)}
      />

      <CreatableSingle
        name="status"
        label="Status"
        required
        defaultValue={{ value: values?.status, label: values?.status }}
        error={errors?.status}
        groupedOptions={statusList}
        inputRef={register({ required: true })}
        onChange={answer => setValue('status', answer.value)}
        setValue={setValue}
      />

      <CustomFields
        register={register}
        errors={errors}
        control={control}
        values={values}
        fields={props?.customFields}
        setValue={setValue}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};
