import { gql } from '@apollo/client';

export const GetIncidents = gql`
  query incidents {
    incidents {
      id
      title
      description
      pta
      nextSteps
      nextUpdateTime
      status
      riskRating
      attribs
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetIncident = gql`
  query incident($id: ID!) {
    incident(id: $id) {
      id
      title
      description
      pta
      nextUpdateTime
      status
      riskRating
      nextSteps
      attribs
      assignments {
        name
        users {
          id
          email
        }
      }
      comments {
        id
        user {
          id
          email
        }
        comment
        createdAt
      }
    }
  }
`;
