import React from 'react';
import { Spinner } from 'react-bootstrap';

export const Loader = props => {
  const { loading } = props;

  return (
    <>
      {loading && (
        <div className="text-center text-c-red errorBlock">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
};
