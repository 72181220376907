import { gql } from '@apollo/client';

export const CreateFinding = gql`
  mutation createFinding(
    $title: String!
    $description: String
    $auditId: ID!
    $likelihoodRatingId: ID
    $impactRatingId: ID
    $riskRegisterId: ID
    $riskMatrixId: ID
    $status: String
    $attribs: JSON
  ) {
    createFinding(
      input: {
        params: {
          title: $title
          description: $description
          auditId: $auditId
          likelihoodRatingId: $likelihoodRatingId
          impactRatingId: $impactRatingId
          riskRegisterId: $riskRegisterId
          riskMatrixId: $riskMatrixId
          status: $status
          attribs: $attribs
        }
      }
    ) {
      finding {
        id
      }
    }
  }
`;

export const UpdateFinding = gql`
  mutation updateFinding(
    $id: ID!
    $title: String!
    $description: String
    $likelihoodRatingId: ID
    $impactRatingId: ID
    $riskRegisterId: ID
    $riskMatrixId: ID
    $attribs: JSON
    $status: String
  ) {
    updateFinding(
      input: {
        id: $id
        details: {
          title: $title
          description: $description
          likelihoodRatingId: $likelihoodRatingId
          impactRatingId: $impactRatingId
          riskRegisterId: $riskRegisterId
          riskMatrixId: $riskMatrixId
          status: $status
          attribs: $attribs
        }
      }
    ) {
      finding {
        id
      }
    }
  }
`;

export const DeleteFinding = gql`
  mutation deleteFinding($id: ID!) {
    deleteFinding(input: { id: $id }) {
      finding {
        id
      }
    }
  }
`;

export const CreateAudit = gql`
  mutation createAudit(
    $title: String!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime
    $certification: String
    $executionParty: String
    $link: String
    $internal: Boolean
    $status: String
    $attribs: JSON
  ) {
    createAudit(
      input: {
        params: {
          endDate: $endDate
          title: $title
          startDate: $startDate
          executionParty: $executionParty
          link: $link
          status: $status
          certification: $certification
          internal: $internal
          attribs: $attribs
        }
      }
    ) {
      audit {
        id
      }
    }
  }
`;

export const UpdateAudit = gql`
  mutation updateAudit(
    $id: ID!
    $title: String!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime
    $certification: String
    $executionParty: String
    $link: String
    $status: String
    $internal: Boolean
    $attribs: JSON
  ) {
    updateAudit(
      input: {
        id: $id
        details: {
          endDate: $endDate
          title: $title
          startDate: $startDate
          executionParty: $executionParty
          link: $link
          status: $status
          certification: $certification
          internal: $internal
          attribs: $attribs
        }
      }
    ) {
      audit {
        id
      }
    }
  }
`;

export const DeleteAudit = gql`
  mutation deleteAudit($id: ID!) {
    deleteAudit(input: { id: $id }) {
      audit {
        id
      }
    }
  }
`;
