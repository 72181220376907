import { CustomFields } from './Custumfields';
import FormInput from 'App/components/Forms/Input';
import { Loader } from 'App/components/Loader';
import React from 'react';

export const RepositoryForm = ({
  register,
  errors,
  control,
  values,
  setValue,
  riskRegisterId,
  impacts,
  likelihoods,
  companyId,
  ...props
}) => {
  React.useEffect(() => {
    register('companyId');
    setValue('companyId', companyId);
    if (values?.id) {
      register('id');
      setValue('id', values?.id);
    }
  }, [setValue, register, values, companyId]);
  return values ? (
    <>
      <FormInput
        name="title"
        defaultValue={values?.title}
        inputRef={register({ required: true })}
        required
        error={errors?.title}
      />

      <CustomFields
        register={register}
        errors={errors}
        control={control}
        values={values}
        fields={props?.customFields}
        setValue={setValue}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};
