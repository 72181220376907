import ModalForm from 'App/components/Forms/ModalForm';
import React from 'react';
import SelectGrouped from 'App/components/Forms/SelectGrouped';
import { useForm } from 'react-hook-form';

export const AddControlTestToDashboardForm = props => {
  const { register, handleSubmit, setValue, errors } = useForm();
  const { data } = props;
  const choices = [
    { label: 'Manual (values filled in attribs)', value: 'attribs' },
    {
      label: 'Automatic (results of workflow execution)',
      value: 'endResultData',
    },
  ];

  React.useEffect(() => {
    register('controlTestId', { required: true });
    register('column', { required: true });
    register('graphType', { required: true });
  }, [register]);

  React.useEffect(() => {
    if (data?.controlTestId) {
      setValue('controlTestId', data?.controlTestId);
    }
    setValue('graphType', 'table');
  }, [data, setValue]);

  return (
    <>
      <ModalForm
        show={props.modalShow}
        title={props.title}
        onHide={props.onHide}
        loader={props.loader}
        onSubmit={handleSubmit(props.onSubmit)}
        fields={
          <>
            <SelectGrouped
              name="column"
              label={'Attributes to show'}
              defaultValue={{}}
              required
              error={errors?.column}
              groupedOptions={choices}
              onChange={answer => setValue('column', answer.value)}
            />
          </>
        }
      />
    </>
  );
};

AddControlTestToDashboardForm.defaultProps = {
  title: 'Assignation',
  type: 'assignation',
  label: 'User to assign the role of: ',
  operation: 'assignation',
};

export default AddControlTestToDashboardForm;
