import { gql } from '@apollo/client';

export const GetAudits = gql`
  query audits {
    audits {
      id
      title
      # description
      status
      startDate
      endDate
      certification
      executionParty
      internal
      link
      attribs
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetAudit = gql`
  query audit($id: ID!) {
    audit(id: $id) {
      id
      title
      # description
      startDate
      endDate
      certification
      executionParty
      internal
      link
      attribs
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetFindings = gql`
  query findings($auditId: ID) {
    findings(auditId: $auditId) {
      id
      title
      description
      status

      likelihoodRating {
        id
        title
      }
      impactRating {
        id
        title
      }
      riskMatrix {
        id
        title
      }
      attribs
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;

export const GetFinding = gql`
  query finding($id: ID!) {
    finding(id: $id) {
      id
      title
      description
      attribs
      riskMatrixId
      impactRatingId
      riskMatrixId
      status
      assignments {
        name
        users {
          id
          email
        }
      }
    }
  }
`;
