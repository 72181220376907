import React from 'react';
import { UpdateUserPreferences } from 'graphql/mutations';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

const usePreferences = () => {
  const [preferenceUpdateMutation, { data: updatedPreferences, error }] =
    useMutation(UpdateUserPreferences);

  const saveToDb = async params => {
    try {
      await preferenceUpdateMutation({
        variables: { details: params },
      });
      return true;
    } catch (graphQLErrors) {
      toast.error(graphQLErrors);
      return false;
    }
  };

  if (error) toast.error('Error when saving preferences.');

  return { saveToDb, updatedPreferences };
};

export { usePreferences };
