import ErrorBoundary from '../ErrorBoundary';
import GdprForm from './GdprForm';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

export const ModalForm = props => {
  const { fields, title, onHide, onSubmit, size } = props;
  return (
    <ErrorBoundary>
      <Modal
        {...props}
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GdprForm
            cancel={onHide}
            cancelButtonLabel={props?.cancelButtonLabel}
            submit={e => onSubmit}
            loading={props?.loader}
            formClasses="form"
            onHide={props.onHide}
            inputs={() => fields}
          />
        </Modal.Body>
      </Modal>
    </ErrorBoundary>
  );
};
ModalForm.defaultProps = {
  size: 'lg',
  title: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
export default ModalForm;
