import { gql } from '@apollo/client';

export const GetSuppliers = gql`
  query suppliers {
    suppliers {
      id
      title
      description
      attribs
      tel
      email
      street
      address
      zipCode
      city
    }
  }
`;

export const GetSupplier = gql`
  query suppliers($id: ID!) {
    supplier(id: $id) {
      id
      title
      description
      attribs
      tel
      email
      street
      address
      zipCode
      city
    }
  }
`;

export const GetSurveys = gql`
  query surveys {
    surveys {
      id
      title
      jsonConfig
    }
  }
`;

export const GetSurvey = gql`
  query survey($id: ID!) {
    survey(id: $id) {
      id
      title
      jsonConfig
    }
  }
`;

export const GetCampaigns = gql`
  query campaigns($supplierExternalId: ID!) {
    campaigns(supplierExternalId: $supplierExternalId) {
      id
      survey {
        id
        title
        jsonConfig
      }
      respondents {
        email
        inviteUrl
        surveyResponses {
          id
          jsonResponse
        }
      }
    }
  }
`;

export const GetCampaign = gql`
  query campaign($id: ID!) {
    campaign(id: $id) {
      id
      status
      survey {
        id
        title
        jsonConfig
        archived
      }
      respondents {
        email
        inviteUrl
        surveyResponses {
          id
          jsonResponse
        }
      }
    }
  }
`;
