import FormInput from 'App/components/Forms/Input';
import { Loader } from 'App/components/Loader';
import React from 'react';
import SelectGrouped from 'App/components/Forms/SelectGrouped';

export const AuditForm = ({ register, errors, values, setValue }) => {
  const [startDate, setStartDate] = React.useState(new Date(values?.startDate));
  const [endDate, setEndDate] = React.useState(new Date(values?.endDate));
  const statusList = [
    { label: 'Open', value: 'open' },
    { label: 'Ongoing', value: 'ongoing' },
    { label: 'Closed', value: 'closed' },
  ];
  const typeAudits = [
    { label: 'External', value: 'false' },
    { label: 'Internal', value: true },
  ];
  React.useEffect(() => {
    register('startDate');
    register('status');
    register('endDate');
    register('internal');
    setValue('startDate', new Date());
    if (values.startDate) setValue('startDate', values?.startDate);
    if (values.endDate) setValue('endDate', values?.endDate);
    if (values?.id) {
      register('id');
      setValue('id', values?.id);
    }
  }, [register, setValue, values]);
  return values ? (
    <>
      <FormInput
        name="title"
        defaultValue={values?.title}
        inputRef={register({ required: true })}
        required
        error={errors?.title}
      />
      <FormInput
        name="startDate"
        inputRef={register({ required: true })}
        type="date"
        required
        defaultValue={startDate}
        error={errors?.startDate}
        onChange={date => {
          setStartDate(date);
          setValue('startDate', date);
        }}
      />
      <FormInput
        name="endDate"
        inputRef={register({ required: false })}
        type="date"
        defaultValue={endDate}
        error={errors?.endDate}
        onChange={date => {
          setEndDate(date);
          setValue('endDate', date);
        }}
      />
      <SelectGrouped
        name="internal"
        required
        label="Internal audit ?"
        defaultValue={values?.internal || 'false'}
        error={errors?.internal}
        groupedOptions={typeAudits}
        // eslint-disable-next-line no-eval
        onChange={answer => setValue('internal', eval(answer.value))}
      />
      <FormInput
        name="certification"
        defaultValue={values.certification}
        inputRef={register({ required: false })}
        error={errors?.certification}
      />
      <FormInput
        name="executionParty"
        defaultValue={values.executionParty}
        inputRef={register({ required: false })}
        error={errors?.executionParty}
      />
      <FormInput
        name="link"
        defaultValue={values.link}
        inputRef={register({ required: false })}
        error={errors?.link}
      />

      <SelectGrouped
        name="status"
        label="Status"
        defaultValue={values?.status}
        error={errors?.status}
        groupedOptions={statusList}
        onChange={answer => setValue('status', answer.value)}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};
