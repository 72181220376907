import useCustomFormSubmit from 'App/hooks/submit-hook';

export const useHasCreateOrEdit = ({ query, refetch }) => {
  const { customHandleSubmit, loading, resultOk } = useCustomFormSubmit(
    query,
    refetch,
  );

  return {
    query: customHandleSubmit,
    loading,
    status: resultOk,
  };
};
