import { gql } from '@apollo/client';

export const GetMaturityScores = gql`
  query maturityScores {
    maturityScores {
      id
      title
      description
      attribs
    }
  }
`;
