import { gql } from '@apollo/client';

export const GetMyCompanyDocuments = gql`
  query {
    me {
      id
      company {
        id
        repositories {
          id
          title
          documents {
            id
            filename
          }
        }
      }
    }
  }
`;
export const GetMyCompanyRepositories = gql`
  query {
    me {
      id
      company {
        id
        repositories {
          id
          title
        }
      }
    }
  }
`;
export const GetMyCompanyFeatures = gql`
  query {
    me {
      id
      company {
        id
        features
      }
    }
  }
`;

export const GetUsers = gql`
  query users {
    users {
      id
      email
      company {
        id
        title
      }
    }
  }
`;

export const GetServerConfig = gql`
  query serverConfig($email: String!) {
    serverConfig(email: $email) {
      clientId
      authorizationUrl
      profile
      accessTokenUri
    }
  }
`;

export const GetUserInfo = gql`
  query {
    me {
      id
      preferences
    }
  }
`;
