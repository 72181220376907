import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { CustomFields } from './Custumfields';
import { Loader } from 'App/components/Loader';
import { NameDescription } from './NameDescription';
import NodeTree from 'App/components/Forms/NodeTree';
import React from 'react';
import SelectGrouped from 'App/components/Forms/SelectGrouped';
import messages from 'messages';
import { Link } from 'react-router-dom';

export const ControlForm = ({
  register,
  errors,
  values,
  control,
  setValue,
  policyId,
  maturityScores,
  standardClauses,
  risks,
  standards,
  ...props
}) => {
  const { intl } = props;
  const i18n = intl.formatMessage;
  const [checkedStandards, setCheckedStandards] = React.useState([]);
  const choosenStandard = React.useRef([]);

  React.useEffect(() => {
    register('maturityScoreId', { required: false });
    register('standardClauseIds', { required: false });
    register('riskId', { required: false });
    register('policyId', { required: true });
    setValue('maturityScoreId', values?.maturityScore?.id);
    setValue('riskId', values?.risk?.id);
    setValue('policyId', policyId);
    if (values?.standardClauses) {
      choosenStandard.current = values?.standardClauses.map(item => item.id);
      setValue('standardClauseIds', choosenStandard.current);
      setCheckedStandards(choosenStandard.current);
    }
    if (values?.id) {
      register('id');
      setValue('id', values?.id);
    }
  }, [register, setValue, values, policyId]);

  const standardCheck = e => {
    choosenStandard.current = e;
    setValue('standardClauseIds', choosenStandard.current);
  };

  const standardTree = standardClauses?.map(item => {
    return {
      label: item.title,
      value: item.title,
      name: item.title,
      children: item.standardClauses.map(ctl => {
        return { value: ctl.id, label: ctl.title, name: ctl.title };
      }),
    };
  });

  return values ? (
    <>
      <NameDescription
        setValue={setValue}
        values={values}
        errors={errors}
        register={register}
      />

      <SelectGrouped
        name="maturityScoreId"
        label={i18n(messages.MaturityScore)}
        defaultValue={values?.maturityScore?.id}
        error={errors?.maturityScoreId}
        groupedOptions={maturityScores?.maturityScores?.map(item => {
          return { label: item.title, value: item.id };
        })}
        onChange={answer => setValue('maturityScoreId', answer.value)}
      />
      {standardTree?.length > 0 ? (
        <NodeTree
          name="standardTree"
          expandOnClick
          label={intl.formatMessage(messages.StandardToInclude)}
          nodes={standardTree}
          checked={checkedStandards}
          onCheck={check => {
            setCheckedStandards([...check]);
            standardCheck(check);
          }}
        />
      ) : (
        <div className="text-center mt-5 h6">
          Select a list of interested standards in the{' '}
          <Link to="/standards">Security Standards configuration</Link>
        </div>
      )}

      <SelectGrouped
        name="riskId"
        label={i18n(messages.Risk)}
        defaultValue={values?.risk?.id}
        error={errors?.riskId}
        groupedOptions={risks?.risks?.map(item => {
          return { label: item.title, value: item.id };
        })}
        onChange={answer => setValue('riskId', answer.value)}
      />
      <CustomFields
        register={register}
        errors={errors}
        control={control}
        values={values}
        fields={props?.customFields}
        setValue={setValue}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};
