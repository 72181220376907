import React from 'react';

const useDocumentTitle = (title, retainOnUnmount = false) => {
  const defaultTitle = React.useRef(document.title);

  React.useEffect(() => {
    document.title = title + ' | Maiky';
  }, [title]);

  React.useEffect(() => {
    const currTitle = defaultTitle.current;
    return () => {
      if (!retainOnUnmount) {
        document.title = currTitle + ' | Maiky';
      }
    };
  }, [retainOnUnmount, defaultTitle]);
};

export default useDocumentTitle;
