import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import FormInput from 'App/components/Forms/Input';
import { Loader } from 'App/components/Loader';
import React from 'react';

export const CommentForm = ({
  register,
  errors,
  values,
  getValues,
  control,
  setValue,
  incidentId,
}) => {
  React.useEffect(() => {
    register('comment');
  }, [register, setValue, values]);

  const [content, setContent] = React.useState({
    description: '',
  });

  const handleQuillEdit = (c, delta, source, editor) => {
    setContent(prev => {
      return {
        ...prev,
        description: editor.getHTML(),
      };
    });
  };
  React.useEffect(() => {
    setValue('comment', content.description);
  }, [setValue, content, getValues]);

  return values ? (
    <>
      <input
        type="hidden"
        ref={register()}
        name="incidentId"
        value={incidentId}
      />
      <FormInput
        name="comment"
        type="ckeditor"
        inputRef={register({ required: true })}
        defaultValue={content.description}
        onChange={handleQuillEdit}
        error={errors?.description}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};
