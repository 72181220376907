import {
  GetControls,
  GetFindings,
  GetMaturityScores,
  GetPolicies,
  GetRisks,
  GetStandardClauses,
  GetUsers,
} from 'graphql/queries';

import ModalForm from 'App/components/Forms/ModalForm';
import React from 'react';
import SelectGrouped from 'App/components/Forms/SelectGrouped';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';

export const AssignForm = props => {
  const { register, handleSubmit, setValue } = useForm();
  let query = GetUsers;
  const { role } = props;
  if (role?.item?.type === 'policy') query = GetPolicies;
  if (role?.item?.type === 'control') query = GetControls;
  if (role?.item?.type === 'risk') query = GetRisks;
  if (role?.item?.type === 'finding') query = GetFindings;
  if (role?.item?.type === 'standardClause') query = GetStandardClauses;
  if (role?.item?.type === 'maturityLevel') query = GetMaturityScores;

  const { data } = useQuery(query);
  const title = React.useRef();
  const label = React.useRef(),
    listData = React.useRef([]);
  title.current = props.title;
  label.current = `User to assign the role of: ${role?.item?.type}`;
  if (role?.item?.operation !== undefined && role?.item?.operation === 'link') {
    title.current = `Link to ${role?.item?.type}`;
    label.current = `${role?.item?.type} to link this resource`;
  }
  if (data) {
    switch (role?.item?.type) {
      case 'policy':
        listData.current = data?.policies?.map(policy => {
          return { label: policy.title, value: policy.id };
        });
        break;
      case 'control':
        listData.current = data?.controls?.map(control => {
          return { label: control.title, value: control.id };
        });
        break;
      case 'risk':
        listData.current = data?.risks?.map(risk => {
          return { label: risk.title, value: risk.id };
        });
        break;
      case 'finding':
        listData.current = data?.findings?.map(finding => {
          return { label: finding.title, value: finding.id };
        });
        break;
      case 'standardClause':
        listData.current = data?.standardClauses?.map(finding => {
          return { label: finding.title, value: finding.id };
        });
        break;
      case 'maturityLevel':
        listData.current = data?.maturityScores?.map(finding => {
          return { label: finding.title, value: finding.id };
        });
        break;
      default:
        listData.current = data?.users?.map(user => {
          return { label: user.email, value: user.id };
        });
        break;
    }
  }

  React.useEffect(() => {
    register('assignerId', { required: true });
  }, [register]);
  return (
    <>
      <ModalForm
        show={props.modalShow}
        title={title.current}
        onHide={props.onHide}
        loader={props.loader}
        onSubmit={handleSubmit(props.onSubmit)}
        fields={
          <>
            {listData.current && (
              <SelectGrouped
                name="assignerId"
                label={label.current}
                defaultValue={{}}
                required
                error={{}}
                groupedOptions={listData.current}
                onChange={answer =>
                  setValue('assignerId', parseInt(answer.value))
                }
              />
            )}
          </>
        }
      />
    </>
  );
};

AssignForm.defaultProps = {
  title: 'Assignation',
  type: 'assignation',
  label: 'User to assign the role of: ',
  operation: 'assignation',
};

export default AssignForm;
