import CreatableSelect from 'react-select/creatable';
import React from 'react';

export const CreatableSingle = props => {
  const {
    groupedOptions,
    name,
    label,
    labelClassName,
    setValue,
    defaultValue,
  } = props;
  const handleChange = (newValue, actionMeta) => {
    setValue(name, newValue?.value);
  };

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name} className={labelClassName}>
          {label ? label : name} {props?.required && '*'}
        </label>
      )}
      <CreatableSelect
        isClearable
        name={name}
        classNamePrefix={name}
        ref={(props.inputRef && props.inputRef()) || props.ref}
        options={groupedOptions}
        defaultValue={defaultValue}
        onChange={props?.onChange || handleChange}
      />
    </div>
  );
};

CreatableSelect.defaultProps = {
  labelClassName: 'form-label',
};
export default CreatableSingle;
