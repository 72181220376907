import { gql } from '@apollo/client';

export const CreateGapAnalysis = gql`
  mutation createGapAnalysisHistory(
    $name: String!
    $gap: Float
    $standardIds: [ID!]
    $controlIds: [ID!]
  ) {
    createGapAnalysisHistory(
      input: {
        params: {
          name: $name
          gap: $gap
          standardIds: $standardIds
          controlIds: $controlIds
        }
      }
    ) {
      gapAnalysisHistory {
        id
      }
    }
  }
`;

export const MakeGapAnalysis = gql`
  mutation makeGapAnalysis($standardIds: [ID!]!, $controlIds: [ID!]!) {
    makeGapAnalysis(
      input: { params: { standardIds: $standardIds, controlIds: $controlIds } }
    ) {
      inPolicyControls {
        id
        title
      }
      overlapControls {
        id
        title
      }
      inStandardClauses {
        id
        title
      }
    }
  }
`;

export const UpdateGapAnalysis = gql`
  mutation updateGapAnalysisHistory(
    $id: ID!
    $name: String!
    $gap: Float
    $standardClauseIds: [ID!]
    $controlIds: [ID!]
  ) {
    updateGapAnalysisHistory(
      input: {
        id: $id
        details: {
          name: $name
          gap: $gap
          standardClauseIds: $standardClauseIds
          controlIds: $controlIds
        }
      }
    ) {
      gapAnalysisHistory {
        id
      }
    }
  }
`;

export const DeleteGapAnalysis = gql`
  mutation deleteGapAnalysisHistory($id: ID!) {
    deleteGapAnalysisHistory(input: { id: $id }) {
      gapAnalysisHistory {
        id
      }
    }
  }
`;

export const UpdateCompany = gql`
  mutation updateCompany($standardIds: [ID!]!) {
    updateCompany(input: { standardIds: $standardIds }) {
      company {
        id
      }
    }
  }
`;
