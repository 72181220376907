import { gql } from '@apollo/client';

export const CreateLikelihoodRating = gql`
  mutation createLikelihoodRating($title: String!, $description: String) {
    createLikelihoodRating(
      input: { params: { title: $title, description: $description } }
    ) {
      likelihoodRating {
        id
      }
    }
  }
`;

export const CreateImpactRating = gql`
  mutation createImpactRating($title: String!, $description: String) {
    createImpactRating(
      input: { params: { title: $title, description: $description } }
    ) {
      impactRating {
        id
      }
    }
  }
`;

export const UpdateImpactRating = gql`
  mutation updateImpactRating(
    $id: ID!
    $title: String!
    $description: String
    $attribs: JSON
  ) {
    updateImpactRating(
      input: {
        id: $id
        details: { title: $title, description: $description, attribs: $attribs }
      }
    ) {
      impactRating {
        id
      }
    }
  }
`;

export const UpdateLikelihoodRating = gql`
  mutation updateLikelihoodRating(
    $id: ID!
    $title: String!
    $description: String
    $attribs: JSON
  ) {
    updateLikelihoodRating(
      input: {
        id: $id
        details: { title: $title, description: $description, attribs: $attribs }
      }
    ) {
      likelihoodRating {
        id
      }
    }
  }
`;
export const AddRiskMatrix = gql`
  mutation createRiskMatrix(
    $riskRegisterId: ID!
    $likelihoodRatingId: ID!
    $impactRatingId: ID!
    $title: String!
    $color: String!
  ) {
    createRiskMatrix(
      input: {
        riskRegisterId: $riskRegisterId
        params: {
          likelihoodRatingId: $likelihoodRatingId
          impactRatingId: $impactRatingId
          title: $title
          color: $color
        }
      }
    ) {
      riskMatrix {
        title
      }
    }
  }
`;

export const AddDataToMatrix = gql`
  mutation addRiskRegisterData(
    $id: ID!
    $rowId: String!
    $rowType: String!
    $newValue: String
  ) {
    addRiskRegisterData(
      input: {
        id: $id
        details: { rowId: $rowId, rowType: $rowType, newValue: $newValue }
      }
    ) {
      riskRegister {
        id
      }
    }
  }
`;

export const DeleteImpactRating = gql`
  mutation deleteImpactRating($riskRegisterId: ID, $id: ID!) {
    deleteImpactRating(input: { riskRegisterId: $riskRegisterId, id: $id }) {
      impactRating {
        id
      }
    }
  }
`;

export const DeleteLikelihoodRating = gql`
  mutation deleteLikelihoodRating($riskRegisterId: ID, $id: ID!) {
    deleteLikelihoodRating(
      input: { riskRegisterId: $riskRegisterId, id: $id }
    ) {
      likelihoodRating {
        id
      }
    }
  }
`;
