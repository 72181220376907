import 'react-datetime/css/react-datetime.css';

import Datetime from 'react-datetime';
import ErrorBoundary from 'App/components/ErrorBoundary';
import PropTypes from 'prop-types';
import React from 'react';
import ReactQuill from 'react-quill';

const FormInput = ({
  name,
  type,
  placeholder,
  id,
  labelClassName,
  inputClassName,
  onChange,
  value,
  error,
  children,
  label,
  defaultValue,
  checkBoxLabel,
  smallText,
  inputRef,
  required,
  ...props
}) => {
  const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
  const name_to_label = str =>
    capitalize(str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1'));
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  let input = (
    <input
      id={name}
      name={id || name}
      ref={inputRef}
      aria-label={name}
      type={type}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      className={inputClassName}
      style={error && { border: 'solid 1px red' }}
    />
  );
  if (type === 'textarea') {
    input = (
      <textarea
        id={name}
        name={id || name}
        ref={inputRef}
        aria-label={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        className={inputClassName}
        style={error && { border: 'solid 1px red' }}
      ></textarea>
    );
  }
  if (type === 'ckeditor') {
    input = (
      <ReactQuill
        id={name}
        placeholder="Type something..."
        theme="snow"
        value={defaultValue}
        onChange={onChange}
        tabIndex={0}
        modules={modules}
        formats={formats}
      />
    );
  }

  if (type === 'date') {
    input = (
      <Datetime
        name={name}
        dateFormat="YYYY-MM-DD"
        timeFormat={false}
        value={defaultValue}
        onChange={onChange}
        style={error && { border: 'solid 1px red' }}
      />
    );
  }
  if (type === 'datetime') {
    input = (
      <Datetime
        name={name}
        value={defaultValue}
        onChange={onChange}
        style={error && { border: 'solid 1px red' }}
      />
    );
  }
  if (type === 'checkbox') {
    input = (
      <div className="checkbx checbox-fll ">
        <input
          type="checkbox"
          className="form-check-input"
          name={name}
          aria-label={name}
          onChange={onChange}
          defaultChecked={defaultValue}
          checked={value}
          ref={inputRef}
          style={error && { border: 'solid 1px red' }}
        />
        <label className="cr form-check-label" htmlFor={name}>
          {checkBoxLabel} {required ? '*' : ''}
        </label>
      </div>
    );
  }

  return (
    <div className={'form-group ' + name}>
      <ErrorBoundary>
        <label htmlFor={name} className={labelClassName}>
          {label ? label : name_to_label(name)} {required ? '*' : ''}
        </label>
        {input}
        {smallText && (
          <small className="text-muted form-text">{smallText}</small>
        )}
        {error && error.type === 'required' && (
          <p style={{ color: 'red' }}>This field is required</p>
        )}
        {error && error.type !== 'required' && (
          <p style={{ color: 'red' }}>{error.message}</p>
        )}
      </ErrorBoundary>
    </div>
  );
};

FormInput.defaultProps = {
  type: 'text',
  labelClassName: 'form-label',
  inputClassName: 'form-control',
  required: false,
};
FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line
  type: PropTypes.string,
  required: PropTypes.bool,
  // eslint-disable-next-line
  type: PropTypes.oneOf([
    'text',
    'number',
    'email',
    'password',
    'textarea',
    'checkbox',
    'ckeditor',
    'date',
    'datetime',
  ]),
  className: PropTypes.string,
  value: PropTypes.any,
};

export default FormInput;
