import { CustomFields } from './Custumfields';
import { Loader } from 'App/components/Loader';
import { NameDescription } from './NameDescription';
import PropTypes from 'prop-types';
import React from 'react';

export const RiskRegisterForm = ({
  register,
  errors,
  control,
  values,
  setValue,
  ...props
}) => {
  React.useEffect(() => {
    if (values?.id) {
      register('id');
      setValue('id', values?.id);
    }
  }, [values, register, setValue]);
  return values ? (
    <>
      <NameDescription
        setValue={setValue}
        values={values}
        errors={errors}
        register={register}
      />

      <CustomFields
        register={register}
        errors={errors}
        control={control}
        values={values}
        fields={props?.customFields}
        setValue={setValue}
      />
    </>
  ) : (
    <Loader loading={true} />
  );
};

RiskRegisterForm.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};
