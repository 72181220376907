import { gql } from '@apollo/client';

export const GetCustomFields = gql`
  query customFields($customizable: Object) {
    customFields(customizable: $customizable) {
      id
      name
      value
      mandatory
      customizable
    }
  }
`;

export const GetCustomField = gql`
  query customField($id: ID!) {
    customField(id: $id) {
      id
      name
      value
      mandatory
      customizable
    }
  }
`;

export const GetImpacts = gql`
  query impactRatings($riskRegisterId: ID) {
    impactRatings(riskRegisterId: $riskRegisterId) {
      id
      title
      description
    }
  }
`;

export const GetLikelihoods = gql`
  query likelihoodRatings($riskRegisterId: ID) {
    likelihoodRatings(riskRegisterId: $riskRegisterId) {
      id
      title
      description
    }
  }
`;

export const GetLikelihood = gql`
  query likelihoodRating($id: ID!) {
    likelihoodRating(id: $id) {
      id
      title
      description
      attribs
    }
  }
`;

export const GetImpact = gql`
  query impactRating($id: ID!) {
    impactRating(id: $id) {
      id
      title
      description
      attribs
    }
  }
`;

export const GetMatrix = gql`
  query riskMatrix(
    $riskRegisterId: ID!
    $impactRatingId: ID
    $likelihoodRatingId: ID
  ) {
    riskMatrix(
      riskRegisterId: $riskRegisterId
      likelihoodRatingId: $likelihoodRatingId
      impactRatingId: $impactRatingId
    ) {
      id
      color
      title
      likelihoodRating {
        id
      }
      impactRating {
        id
      }
    }
  }
`;

export const GetChartConfigs = gql`
  query chartConfigs {
    chartConfigs {
      companyId
      config
      id
      sortOrder
      data
    }
  }
`;

export const GetSearchQuery = gql`
  query elasticsearch($query: String!) {
    elasticsearch(query: $query) {
      data
    }
  }
`;
