import { gql } from '@apollo/client';

export const loginMutationGQL = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(input: { email: $email, password: $password }) {
      token
      user {
        id
        email
        company {
          id
          title
          features
        }
      }
    }
  }
`;
export const ResetUserPassword = gql`
  mutation resetUserPassword($token: String!, $password: String!) {
    resetUserPassword(input: { token: $token, password: $password }) {
      user {
        id
      }
    }
  }
`;
export const Logout = gql`
  mutation logoutUser {
    logoutUser(input: {}) {
      user {
        id
      }
    }
  }
`;
