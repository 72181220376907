import React from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

const useCustomFormSubmit = (mutation, refetch) => {
  const [resultOk, setResultOk] = React.useState(false);
  const [operation, { data: result, loading, error }] = useMutation(mutation);

  const onSubmit = async values => {
    try {
      await operation({
        variables: values,
      });
    } catch (graphQLErrors) {
      toast.error(graphQLErrors);
    }
  };

  const customHandleSubmit = (formData, e) => {
    if (e) e.preventDefault();
    setResultOk(false);
    return onSubmit(formData);
  };

  React.useEffect(() => {
    if (result) {
      setResultOk(true);
      toast.success('Action succesfully completed!');
      if (refetch) refetch();
    }
    if (error) {
      toast.error(error);
    }
  }, [result, error, refetch]);

  return { customHandleSubmit, loading, resultOk };
};
export default useCustomFormSubmit;
