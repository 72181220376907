import '../../node_modules/font-awesome/scss/font-awesome.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import React, { Suspense } from 'react';

import Aux from 'hoc/_Aux';
import { FlagsProvider } from 'flagged';
import { GetUserInfo } from 'graphql/queries';
import { Helmet } from 'react-helmet';
import Loadable from 'react-loadable';
import Loader from 'App/layout/Loader';
import ScrollToTop from 'App/layout/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import { UserPreferencesContext } from './context';
import config from 'config';
import mainRoutes from 'routes';
import reducer from 'store/reducer';
import routes from 'auth-route';
import { useCheckFeature } from 'App/hooks';
import { useInjectReducer } from 'utils/injectReducer';
import { useLazyQuery } from '@apollo/client';

// export const UserPreferencesContext = React.createContext({data: {}, setData: () => {}});
const AdminLayout = Loadable({
  loader: () => import('App/layout/AdminLayout'),
  loading: Loader,
});

export const RequireAuth = ({ children }) => {
  const token = localStorage.getItem('token');
  let location = useLocation();
  if (!token) {
    return <Navigate to={'/auth/signin'} state={{ from: location }} />; //On renvoie vers notre page de login si le token n'est pas présent.
  }
  return children;
};

export const AlreadyAuthed = ({ children }) => {
  const token = localStorage.getItem('token');
  let location = useLocation();
  if (token) {
    return <Navigate to={config.afterLoginPath} state={{ from: location }} />; //On renvoie vers notre page de login si le token n'est pas présent.
  }
  return children;
};

export function App(props) {
  useInjectReducer({ key: 'global', reducer });
  const [getUserInfo, { data }] = useLazyQuery(GetUserInfo);
  const state = React.useContext(UserPreferencesContext);
  const { client } = props;
  const menu = routes.map((route, index) => {
    return (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        element={
          <AlreadyAuthed>
            <route.component />
          </AlreadyAuthed>
        }
      />
    );
  });
  let flags = [];
  const mainMenu = mainRoutes.map((route, index) => {
    if (route?.flags) flags = [...flags, ...route?.flags];
    return (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        element={
          <RequireAuth>
            <route.component client={client} title={route.name} />
          </RequireAuth>
        }
      />
    );
  });

  // flags.push(JSON.parse(localStorage.getItem('user')));
  React.useEffect(() => {
    if (
      localStorage.getItem('token') &&
      localStorage.getItem('expires_in') > Date.now()
    ) {
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (data) state.setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (flags?.company?.features) flags = [...flags, ...flags?.company?.features];
  return (
    <Aux>
      <Helmet titleTemplate="%s - Maiky" defaultTitle="GRC | Maiky">
        <meta name="description" content="Maiky, your GRC portal" />
      </Helmet>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes>
            {menu}
            <Route
              path="/*"
              element={
                <FlagsProvider features={flags}>
                  <AdminLayout client={client} menu={mainMenu} />
                </FlagsProvider>
              }
            />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Aux>
  );
}

export default App;
